<template>
  <div class="item" :class="{ 'item--disabled': isDisabled }">
    <input
      type="radio"
      :name="name"
      :value="usedValue"
      :id="`type--${usedValue}`"
      v-model="computedType"
    />
    <label :for="`type--${usedValue}`" class="item__name">
      {{ item.name }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
      required: true
    },
    currentType: {
      type: [String, Number],
      default: null
    },
    name: {
      type: String,
      default: null,
      required: true
    },
    isDisabled: {
      type: Boolean
    },
    keyToUse: {
      type: String,
      default: 'id'
    }
  },
  name: 'SelectionItem',
  computed: {
    usedValue() {
      // return this.item.value ? this.item.value : this.item.id
      return this.item[this.keyToUse]
    },
    computedType: {
      get() {
        return this.currentType
      },
      set(type) {
        if (!this.isDisabled) {
          this.$emit('updateType', type)
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.item
  box-sizing: border-box
  &:not(:last-child)
    +media((margin-bottom: (320: rem(22), 768: 0)))
  &:first-child
    .item__name
      +media((border-radius: (768: rem(4) 0 0 rem(4))))
  &:last-child
    .item__name
      +media((border-radius: (768: 0 rem(4) rem(4) 0)))
  input
    display: none
    position: absolute
    pointer-events: none
    opacity: 0
    &:checked
      + .item__name
          +media((background: (768: $red)))
          +media((color: (768: $white)))
          +media((border-color: (768: $red)))
          // +media((font-weight: (320: bold, 768: 500)))
          &:before
            background: $red
            border-color: $red
          &:after
            opacity: 1
  &__name
    color: $black
    +media((padding: (768: rem(16))))
    +media((border: (768: rem(1) solid $another-white)))
    @extend %custom-radio-label
    &:before, &:after
      +media((display: (768: none)))
    @media(any-hover: hover)
      &:hover
        +media((border-color: (768: $red)))
  &--disabled
    .item
      &__name
        cursor: no-drop
        pointer-events: none
        opacity: .5
        // @media(any-hover: hover)
          &:hover
            +media((border-color: (768: $tn_gray)))
</style>
