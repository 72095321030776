<template>
  <div class="load-bearing">
    <switcher :is-toggled="isEnabled" id="load-bearing-switcher" @updateStatus="updateStatus" />
    <div class="title">Несущая способность крепежа</div>
    <div class="question">
      <tippy :max-width="240">
        <template #trigger>
          <img src="@/assets/img/question.svg" alt="?" />
        </template>
        {{ tooltipText }}
      </tippy>
    </div>
    <input-with-units
      :value="value"
      id="load-bearing"
      @updateValue="updateValue"
      :is-enabled="isEnabled"
    >
      H
    </input-with-units>
  </div>
</template>

<script>
import Switcher from '@/components/dump/switcher'
import InputWithUnits from '@/components/dump/inputWithUnits'
const tooltipText =
  'Недопустимо применять усреднённое значение несущей способности крепежа (НСК) по результатам полевых испытаний. Вводимая величина НСК определяется согласно методологии CEN/TS 17659 с учётом коэффициентов запаса 0,5-0,7 и статистической погрешности. При расчёте выбирается наименьшее значение между несущей способностью крепежа и сопротивлением раздиру мембраны'

export default {
  props: {
    isEnabled: {
      type: Boolean,
      required: true
    },
    value: {
      type: Number,
      required: true,
      default: 0
    }
  },
  name: 'LoadBearingCapacity',
  components: { InputWithUnits, Switcher },
  methods: {
    updateStatus(status) {
      this.$emit('updateStatus', status)
    },
    updateValue(val) {
      this.$emit('updateValue', val)
    }
  },
  computed: {
    tooltipText: () => tooltipText
  }
}
</script>

<style scoped lang="sass">
.materials__modal
  .load-bearing
    background: transparent
    margin: 0
.load-bearing
  display: flex
  align-items: center
  padding: rem(18) rem(40)
  background: $white
  border-radius: rem(4)
  margin-top: rem(34)
  width: 100%
  .title
    @extend %14
    color: $black
    +media((margin: (320: 0 rem(5) 0 rem(10), 400: 0 rem(10) 0 rem(18))))
    text-align: left
  .question
    line-height: 0
  .input-wrapper
    +media((margin-left: (320: rem(10), 400: rem(18))))
</style>
