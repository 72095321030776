<template>
  <section class="wizard">
    <component
      v-if="insulationMaterials.length"
      :is="sectorFromState.inAppStep"
      @goToLocation="goToLocation"
      @goToInsulation="goToInsulation"
      @goToBase="goToBase"
      @goToDampCourse="goToDampCourse"
      @goToSector="goToSector"
      @goToMinorResult="goToMinorResult"
      @addFromNav="addSector"
      @addSector="addSector"
      @showDeleteConfirmation="showDeleteConfirmation"
      ref="Steps"
    >
    </component>
    <transition name="fading">
      <DeleteConfirmation
        v-if="isShowDeleteConfirmation"
        @confirm="deleteSector"
        @cancel="isShowDeleteConfirmation = !isShowDeleteConfirmation"
      />
    </transition>
  </section>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import Base from '@/components/smart/Base.vue'
import Insulation from '@/components/smart/Insulation.vue'
import DampCourse from '@/components/smart/DampCourse.vue'
import MinorResult from '@/components/smart/MinorResult.vue'
import Sector from '@/components/smart/Sector.vue'
import DeleteConfirmation from '@/components/smart/DeleteConfirmation.vue'
import { stepNumbers } from '@/utils/stepNumbers'

export default {
  data: () => ({
    isShowDeleteConfirmation: false
  }),
  components: {
    Base,
    Insulation,
    DampCourse,
    MinorResult,
    Sector,
    DeleteConfirmation
  },
  methods: {
    ...mapMutations({
      ADD_NEW_SECTOR: 'ADD_NEW_SECTOR',
      UPDATE_SECTOR_STEP: 'UPDATE_SECTOR_STEP',
      DELETE_SECTOR: 'DELETE_SECTOR'
    }),
    scrollTop() {
      window.parent.postMessage({ scrollTop: true }, '*')
      window.scrollTo(0, 0)
    },
    goToLocation() {
      this.$router.push('/location')
    },
    goToBase() {
      this.UPDATE_SECTOR_STEP({
        id: this.sectorId,
        val: 'Base'
      })
      this.scrollTop()
    },
    goToInsulation() {
      this.UPDATE_SECTOR_STEP({
        id: this.sectorId,
        val: 'Insulation'
      })
      this.scrollTop()
    },
    goToDampCourse() {
      this.UPDATE_SECTOR_STEP({
        id: this.sectorId,
        val: 'DampCourse'
      })
      this.scrollTop()
    },
    goToSector() {
      this.UPDATE_SECTOR_STEP({
        id: this.sectorId,
        val: 'Sector'
      })
      this.scrollTop()
    },
    goToMinorResult() {
      this.UPDATE_SECTOR_STEP({
        id: this.sectorId,
        val: 'MinorResult'
      })
      this.scrollTop()
    },
    addSector() {
      this.ADD_NEW_SECTOR()
      this.$router.push(`/wizard/${this.sectors.length - 1}`)
      this.scrollTop()
      setTimeout(
        () => this.$refs.Steps.$refs.Nav.$refs.mySwiper.swiper.slideTo(this.sectors.length, 1000),
        0
      )
    },
    showDeleteConfirmation() {
      this.isShowDeleteConfirmation = true
      this.$root.offBodyScroll()
    },
    deleteSector() {
      if (this.sectors.length - 1 === parseInt(this.sectorId, 10)) {
        this.DELETE_SECTOR(this.sectorId)
        this.$router.push(`/wizard/${this.sectorId - 1}`)
        this.$root.onBodyScroll()
      } else {
        this.DELETE_SECTOR(this.sectorId)
        this.$root.onBodyScroll()
      }
    },
    updateMetric() {
      this.$emit('updateStep', stepNumbers[this.sectorFromState.inAppStep])
    }
  },
  watch: {
    isShowDeleteConfirmation() {
      // eslint-disable-next-line
      this.isShowDeleteConfirmation === true
        ? this.$root.offBodyScroll()
        : this.$root.onBodyScroll()
    },
    'sectorFromState.inAppStep': {
      handler: 'updateMetric',
      immediate: true
    }
  },
  mounted() {
    if (!this.location.zone) {
      this.$router.push('/')
    }
  },
  computed: {
    ...mapState({
      sectors: state => state.sectors,
      location: state => state.location,
      insulationMaterials: state => state.insulationMaterials
    }),
    sectorId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorId)
    }
  }
}
</script>
<style lang="sass" scoped>
.fading-enter-active, .fading-leave-active
  transition: opacity .5s

.fading-enter, .fading-leave-to
  opacity: 0
</style>
