<template>
  <div class="figure__wrapper">
    <svg
      :width="defaultSvgParams.width * figureSvgScale"
      :height="defaultSvgParams.height * figureSvgScale"
      :viewBox="`0 0 ${defaultSvgParams.width * figureSvgScale}
           ${defaultSvgParams.height * figureSvgScale}`"
      ref="figureCode"
    >
      <path
        class="scheme-circuit"
        :d="square"
        fill="#F6F6F6"
        :stroke="figureStrokeColor"
        :stroke-width="strokeWidth"
        style="stroke-linecap: round"
      ></path>
      // square
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="(defaultSvgParams.width * figureSvgScale) / 2"
        :y="changeHeightUp + bias / 2"
        v-if="!isNav"
      >
        D
      </text>
      // top d
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="(defaultSvgParams.width * figureSvgScale) / 2"
        :y="changeHeightDown - bias / 2"
        v-if="!isNav"
      >
        D
      </text>
      // bottom d
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="changeWidthLeft + bias / 2"
        :y="(defaultSvgParams.height * figureSvgScale) / 2"
        v-if="!isNav"
      >
        D
      </text>
      // left d
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="changeWidthRight - bias / 2"
        :y="(defaultSvgParams.height * figureSvgScale) / 2"
        v-if="!isNav"
      >
        D
      </text>
      // right d
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="changeWidthLeft + bias / 2"
        :y="changeHeightUp + bias / 2"
        v-if="!isNav"
      >
        C
      </text>
      // left top
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="changeWidthLeft + bias / 2"
        :y="changeHeightDown - bias / 2"
        v-if="!isNav"
      >
        C
      </text>
      // left bottom
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="changeWidthRight - bias / 2"
        :y="changeHeightUp + bias / 2"
        v-if="!isNav"
      >
        C
      </text>
      // right top
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="changeWidthRight - bias / 2"
        :y="changeHeightDown - bias / 2"
        v-if="!isNav"
      >
        C
      </text>
      // right bottom
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="(defaultSvgParams.width * figureSvgScale) / 2"
        :y="changeHeightDown + 25"
        v-if="!isNav"
      >
        l = {{ sideL * 1000 }}
      </text>
      // l-info
      <text
        alignment-baseline="middle"
        text-anchor="middle"
        :x="changeWidthRight + 25"
        :y="(defaultSvgParams.height * figureSvgScale) / 2"
        :transform="`rotate(-90 ${changeWidthRight + 25}
            ${(defaultSvgParams.height * figureSvgScale) / 2})`"
        v-if="!isNav"
      >
        b = {{ sideB * 1000 }}
      </text>
      // b-info
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="changeWidthLeft + biasAngle / 2"
        :y="changeHeightUp - 40"
        v-if="!isNav"
      >
        {{ Math.round((this.min / 5) * 1000) }}
      </text>
      // corner bias horizontal info
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="changeWidthLeft - 40"
        :y="changeHeightUp + biasAngle / 2"
        :transform="`rotate(-90 ${changeWidthLeft - 40}
            ${changeHeightUp + biasAngle / 2})`"
        v-if="!isNav"
      >
        {{ Math.round((this.min / 5) * 1000) }}
      </text>
      // corner bias vertical info
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="changeWidthRight - biasAngle - bias"
        :y="changeHeightUp - 40"
        v-if="!isNav"
      >
        {{ Math.round((this.min / 10) * 1000) }}
      </text>
      // bias vertical info
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="changeWidthLeft - 40"
        :y="changeHeightDown - biasAngle - bias"
        :transform="`rotate(-90 ${changeWidthLeft - 40}
            ${changeHeightDown - biasAngle - bias})`"
        v-if="!isNav"
      >
        {{ Math.round((this.min / 10) * 1000) }}
      </text>
      // bias horizontal info
      <line
        :x1="changeWidthLeft + normalizeBiasAngle"
        :y1="changeHeightUp"
        :x2="changeWidthLeft + normalizeBiasAngle"
        :y2="changeHeightUp + bias"
        stroke="#333333"
        stroke-width="1"
        style="stroke-linecap: round"
        stroke-dasharray="6"
        v-if="!isNav"
      ></line>
      // corner left top right side
      <line
        :x1="changeWidthLeft"
        :y1="changeHeightUp + normalizeBiasAngle"
        :x2="changeWidthLeft + bias"
        :y2="changeHeightUp + normalizeBiasAngle"
        stroke="#333333"
        stroke-width="1"
        style="stroke-linecap: round"
        stroke-dasharray="6"
        v-if="!isNav"
      ></line>
      // corner left top bottom side
      <line
        :x1="changeWidthLeft + normalizeBiasAngle"
        :y1="changeHeightDown"
        :x2="changeWidthLeft + normalizeBiasAngle"
        :y2="changeHeightDown - bias"
        stroke="#333333"
        stroke-width="1"
        style="stroke-linecap: round"
        stroke-dasharray="6"
        v-if="!isNav"
      ></line>
      // corner left bottom right side
      <line
        :x1="changeWidthLeft"
        :y1="changeHeightDown - normalizeBiasAngle"
        :x2="changeWidthLeft + bias"
        :y2="changeHeightDown - normalizeBiasAngle"
        stroke="#333333"
        stroke-width="1"
        style="stroke-linecap: round"
        stroke-dasharray="6"
        v-if="!isNav"
      ></line>
      // corner left bottom top side
      <line
        :x1="changeWidthRight - normalizeBiasAngle"
        :y1="changeHeightUp"
        :x2="changeWidthRight - normalizeBiasAngle"
        :y2="changeHeightUp + bias"
        stroke="#333333"
        stroke-width="1"
        style="stroke-linecap: round"
        stroke-dasharray="6"
        v-if="!isNav"
      ></line>
      // corner right top left side
      <line
        :x1="changeWidthRight"
        :y1="changeHeightUp + normalizeBiasAngle"
        :x2="changeWidthRight - bias"
        :y2="changeHeightUp + normalizeBiasAngle"
        stroke="#333333"
        stroke-width="1"
        style="stroke-linecap: round"
        stroke-dasharray="6"
        v-if="!isNav"
      ></line>
      // corner right top bottom side
      <line
        :x1="changeWidthRight - normalizeBiasAngle"
        :y1="changeHeightDown"
        :x2="changeWidthRight - normalizeBiasAngle"
        :y2="changeHeightDown - bias"
        stroke="#333333"
        stroke-width="1"
        style="stroke-linecap: round"
        stroke-dasharray="6"
        v-if="!isNav"
      ></line>
      // corner right bottom left side
      <line
        :x1="changeWidthRight"
        :y1="changeHeightDown - normalizeBiasAngle"
        :x2="changeWidthRight - bias"
        :y2="changeHeightDown - normalizeBiasAngle"
        stroke="#333333"
        stroke-width="1"
        style="stroke-linecap: round"
        stroke-dasharray="6"
        v-if="!isNav"
      ></line>
      // corner right top bottom side
      <path
        :d="dashedSquare"
        stroke-dasharray="6"
        :stroke="figureStrokeColor"
        fill="#F6F6F6"
        stroke-width="1"
        style="stroke-linecap: round"
        v-if="!isNav"
      ></path>
      // dashed square
      <path
        :d="arrowWidth"
        fill="none"
        stroke="#999999"
        stroke-width="2"
        style="stroke-linecap: round"
        v-if="!isNav"
      ></path>
      // arrow width
      <path
        :d="arrowCornerBiasHorizontal"
        fill="none"
        stroke="#999999"
        stroke-width="2"
        style="stroke-linecap: round"
        v-if="!isNav"
      ></path>
      // arrow corner bias horizontal
      <path
        :d="arrowHeight"
        fill="none"
        stroke="#999999"
        stroke-width="2"
        style="stroke-linecap: round"
        v-if="!isNav"
      ></path>
      // arrow height
      <path
        :d="arrowCornerBiasVertical"
        fill="none"
        stroke="#999999"
        stroke-width="2"
        style="stroke-linecap: round"
        v-if="!isNav"
      ></path>
      // arrow corner bias vertical
      <path
        :d="arrowBiasVertical"
        fill="none"
        stroke="#999999"
        stroke-width="2"
        style="stroke-linecap: round"
        v-if="!isNav"
      ></path>
      // arrow bias vertical
      <path
        :d="arrowBiasHorizontal"
        fill="none"
        stroke="#999999"
        stroke-width="2"
        style="stroke-linecap: round"
        v-if="!isNav"
      ></path>
      // arrow bias horizontal
      <text
        alignment-baseline="middle"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="(defaultSvgParams.width * figureSvgScale) / 2"
        :y="(defaultSvgParams.height * figureSvgScale) / 2"
        v-if="!isNav"
      >
        E
      </text>
      <!--<g class="overlay">
        <path :d="cornerLeftTop"
              stroke="transparent"
              stroke-width="4"
              fill="black"
              style="stroke-linecap: round;"
              v-if="!isNav"></path>
      </g>-->
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    defaultSvgParams: Object,
    isNav: Boolean,
    isDetails: Boolean,
    navId: Number,
    navB: Number,
    navL: Number
  },
  data() {
    return {}
  },
  components: {},
  watch: {},
  methods: {},
  computed: {
    scaleB() {
      // коэффициент b
      return (this.defaultSvgParams.baseHeight * this.figureSvgScale * 100) / this.sideB
    },
    scaleL() {
      // коэффициент l
      return (this.defaultSvgParams.baseWidth * this.figureSvgScale * 100) / this.sideL
    },
    scale() {
      // применяемый коэффициент в зависимости от того какой из коэффициентов больше
      return this.scaleB > this.scaleL ? this.scaleL : this.scaleB
    },
    changeWidthLeft() {
      // изменяем ширину влево
      return (this.defaultSvgParams.width * this.figureSvgScale) / 2 - (this.sideL * this.scale) / 2
    },
    changeWidthRight() {
      // изменяем ширину вправо
      return (this.defaultSvgParams.width * this.figureSvgScale) / 2 + (this.sideL * this.scale) / 2
    },
    changeHeightUp() {
      // изменяем высоту вверх
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 - (this.sideB * this.scale) / 2
      )
    },
    changeHeightDown() {
      // изменяем высоту вниз
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 + (this.sideB * this.scale) / 2
      )
    },
    figureSvgScale() {
      return this.isNav ? this.defaultSvgParams.navScale : 1
    },
    figureStrokeColor() {
      return this.isNav || this.isDetails ? '#959595' : '#333333'
    },
    strokeWidth() {
      return this.isNav || this.isDetails ? 2 : 3
    },
    max() {
      return this.sideL > this.sideB ? this.sideL : this.sideB
    },
    min() {
      return this.sideL < this.sideB ? this.sideL : this.sideB
    },
    bias() {
      return (this.min / 10) * this.scale
    },
    biasAngle() {
      return (this.min / 5) * this.scale
    },
    normalizeBiasAngle() {
      return this.biasAngle < (this.min / 2) * this.scale
        ? this.biasAngle
        : (this.min / 2) * this.scale
      // return this.biasAngle;
    },
    square() {
      return `M${this.changeWidthLeft},${this.changeHeightUp}
      L${this.changeWidthLeft},${this.changeHeightDown}
      L${this.changeWidthRight},${this.changeHeightDown}
      L${this.changeWidthRight},${this.changeHeightUp} Z`
    },
    dashedSquare() {
      return `M${this.changeWidthLeft + this.bias},${this.changeHeightUp + this.bias}
      L${this.changeWidthLeft + this.bias},${this.changeHeightDown - this.bias}
      L${this.changeWidthRight - this.bias},${this.changeHeightDown - this.bias}
      L${this.changeWidthRight - this.bias},${this.changeHeightUp + this.bias}Z`
    },
    cornerLeftTop() {
      return `M${this.changeWidthLeft},${this.changeHeightUp}
      L${this.changeWidthLeft + this.biasAngle},${this.changeHeightUp}
      L${this.changeWidthLeft + this.biasAngle},${this.changeHeightUp + this.bias}
      L${this.changeWidthLeft + this.biasAngle / 2},${this.changeHeightUp + this.bias}
      L${this.changeWidthLeft + this.biasAngle / 2},${this.changeHeightUp + this.biasAngle}
      L${this.changeWidthLeft},${this.changeHeightUp + this.biasAngle}

      Z`
    },
    arrowWidth() {
      return `M${this.changeWidthLeft},${this.changeHeightDown + 40}
      L${this.changeWidthRight},${this.changeHeightDown + 40}
      M${this.changeWidthLeft + 6},${this.changeHeightDown + 35}
      L${this.changeWidthLeft},${this.changeHeightDown + 40}
      M${this.changeWidthLeft + 6},${this.changeHeightDown + 45}
      L${this.changeWidthLeft},${this.changeHeightDown + 40}
      M${this.changeWidthRight - 6},${this.changeHeightDown + 35}
      L${this.changeWidthRight},${this.changeHeightDown + 40}
      M${this.changeWidthRight - 6},${this.changeHeightDown + 45}
      L${this.changeWidthRight},${this.changeHeightDown + 40}`
    },
    arrowHeight() {
      return `M${this.changeWidthRight + 40},${this.changeHeightUp}
      L${this.changeWidthRight + 40},${this.changeHeightDown}
      M${this.changeWidthRight + 45},${this.changeHeightUp + 6}
      L${this.changeWidthRight + 40},${this.changeHeightUp}
      M${this.changeWidthRight + 35},${this.changeHeightUp + 6}
      L${this.changeWidthRight + 40},${this.changeHeightUp}
      M${this.changeWidthRight + 45},${this.changeHeightDown - 6}
      L${this.changeWidthRight + 40},${this.changeHeightDown}
      M${this.changeWidthRight + 35},${this.changeHeightDown - 6}
      L${this.changeWidthRight + 40},${this.changeHeightDown}`
    },
    arrowCornerBiasHorizontal() {
      return `M${this.changeWidthLeft},${this.changeHeightUp - 20}
      L${this.changeWidthLeft + this.biasAngle},${this.changeHeightUp - 20}
      M${this.changeWidthLeft + 6},${this.changeHeightUp - 15}
      L${this.changeWidthLeft},${this.changeHeightUp - 20}
      M${this.changeWidthLeft + 6},${this.changeHeightUp - 25}
      L${this.changeWidthLeft},${this.changeHeightUp - 20}
      M${this.changeWidthLeft + this.biasAngle - 6},${this.changeHeightUp - 15}
      L${this.changeWidthLeft + this.biasAngle},${this.changeHeightUp - 20}
      M${this.changeWidthLeft + this.biasAngle - 6},${this.changeHeightUp - 25}
      L${this.changeWidthLeft + this.biasAngle},${this.changeHeightUp - 20}`
    },
    arrowCornerBiasVertical() {
      return `M${this.changeWidthLeft - 20},${this.changeHeightUp}
      L${this.changeWidthLeft - 20},${this.changeHeightUp + this.biasAngle}
      M${this.changeWidthLeft - 25},${this.changeHeightUp + 6}
      L${this.changeWidthLeft - 20},${this.changeHeightUp}
      M${this.changeWidthLeft - 15},${this.changeHeightUp + 6}
      L${this.changeWidthLeft - 20},${this.changeHeightUp}
      M${this.changeWidthLeft - 25},${this.changeHeightUp + this.biasAngle - 6}
      L${this.changeWidthLeft - 20},${this.changeHeightUp + this.biasAngle}
      M${this.changeWidthLeft - 15},${this.changeHeightUp + this.biasAngle - 6}
      L${this.changeWidthLeft - 20},${this.changeHeightUp + this.biasAngle}`
    },
    arrowBiasVertical() {
      return `M${this.changeWidthRight - this.biasAngle - this.bias},${this.changeHeightUp}
      L${this.changeWidthRight - this.biasAngle - this.bias},${this.changeHeightUp + this.bias + 20}
      M${this.changeWidthRight - this.biasAngle - this.bias},${this.changeHeightUp + this.bias}
      M${this.changeWidthRight - this.biasAngle - this.bias - 5},${this.changeHeightUp - 6}
      L${this.changeWidthRight - this.biasAngle - this.bias},${this.changeHeightUp}
      M${this.changeWidthRight - this.biasAngle - this.bias + 5},${this.changeHeightUp - 6}
      L${this.changeWidthRight - this.biasAngle - this.bias},${this.changeHeightUp}
      M${this.changeWidthRight - this.biasAngle - this.bias - 5},
      ${this.changeHeightUp + this.bias + 6}
      L${this.changeWidthRight - this.biasAngle - this.bias},${this.changeHeightUp + this.bias}
      M${this.changeWidthRight - this.biasAngle - this.bias + 5},
      ${this.changeHeightUp + this.bias + 6}
      L${this.changeWidthRight - this.biasAngle - this.bias},${this.changeHeightUp + this.bias}
      M${this.changeWidthRight - this.biasAngle - this.bias},${this.changeHeightUp}
      L${this.changeWidthRight - this.biasAngle - this.bias},${this.changeHeightUp - 20}`
    },
    arrowBiasHorizontal() {
      return `M${this.changeWidthLeft},${this.changeHeightDown - this.biasAngle - this.bias}
      L${this.changeWidthLeft + this.bias + 20},
      ${this.changeHeightDown - this.biasAngle - this.bias}
      M${this.changeWidthLeft + this.bias},${this.changeHeightDown - this.biasAngle - this.bias}
      M${this.changeWidthLeft - 6},${this.changeHeightDown - this.biasAngle - this.bias - 5}
      L${this.changeWidthLeft},${this.changeHeightDown - this.biasAngle - this.bias}
      M${this.changeWidthLeft - 6},${this.changeHeightDown - this.biasAngle - this.bias + 5}
      L${this.changeWidthLeft},${this.changeHeightDown - this.biasAngle - this.bias}
      M${this.changeWidthLeft + this.bias + 6},
      ${this.changeHeightDown - this.biasAngle - this.bias - 5}
      L${this.changeWidthLeft + this.bias},${this.changeHeightDown - this.biasAngle - this.bias}
      M${this.changeWidthLeft + this.bias + 6},
      ${this.changeHeightDown - this.biasAngle - this.bias + 5}
      L${this.changeWidthLeft + this.bias},${this.changeHeightDown - this.biasAngle - this.bias}
      M${this.changeWidthLeft},${this.changeHeightDown - this.biasAngle - this.bias}
      L${this.changeWidthLeft - 20},${this.changeHeightDown - this.biasAngle - this.bias}`
    },
    sectorId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    // sectorFromState() {
    //   return this.$store.getters.getSector(this.sectorId);
    // },
    sectorFromState() {
      return !this.isNav && !this.isDetails
        ? this.$store.getters.getSector(this.sectorId)
        : this.$store.getters.getSector(this.navId)
    },
    sideB() {
      return this.sectorFromState.sideB
    },
    sideL() {
      return this.sectorFromState.sideL
    }
  },
  mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
svg
  max-width: 100%
  height: auto
  path
    pointer-events: none
  text
    font-weight: 500
  .overlay
    path
      fill: #F6F6F6
    text
      color: $black
    &:hover
      text
        color: #fff
      path
        fill: #f7b4b7
</style>
