<template>
  <div class="row">
    <div class="minor__title dampCourse__title" :class="{ modal__title: isInModal }">
      Выберите материал гидроизоляции
      <button v-if="isInModal" class="modal__title-btn" @click="close">
        <img src="@/assets/img/close-mini.svg" alt="❌" />
      </button>
    </div>
    <div class="dampCourse__filter">
      <label
        for="category-all"
        class="dampCourse__filter-item"
        :class="{ active: pickedCategory === '' }"
      >
        <input type="radio" value="" v-model="pickedCategory" id="category-all" />
        <span class="name">Все материалы</span>
      </label>
      <label
        :for="`category-${category.id}`"
        v-for="category in categories"
        :key="category.id"
        :class="{ active: pickedCategory === category.id }"
        class="dampCourse__filter-item"
      >
        <input
          type="radio"
          :value="category.id"
          v-model="pickedCategory"
          :id="`category-${category.id}`"
        />
        <span class="name">{{ category.name }}</span>
      </label>
    </div>
    <div class="hint" v-if="isShowHint">{{ hintText }}</div>
    <div class="dampCourse__materials" v-else>
      <div
        class="dampCourse__materials-item"
        :class="{ active: material.id === item.id, shadow: isInModal }"
        v-for="item in filteredCurrentMaterialsByCategory"
        :key="item.id"
      >
        <label :for="`material-${item.id}`" class="dampCourse__materials-item--label">
          <input
            type="radio"
            :value="item.id"
            :id="`material-${item.id}`"
            v-model="material.id"
            @change="updateSectorMaterial"
          />
          <span class="dampCourse__materials-item--img">
            <img :src="item.src" :alt="item.name" />
          </span>
          <span class="dampCourse__materials-item--name">
            {{ item.name }}
            <label :for="`info-material-${item.id}`" class="showInfo-btn">
              <input
                type="radio"
                v-model="showInfo"
                :id="`info-material-${item.id}`"
                :value="item.id"
              />
              <materials-info-icon />
            </label>
          </span>
        </label>
        <div class="info__overlay" v-if="showInfo === item.id" @click="showInfo = ''">
          <div class="info">
            <button class="close" @click="showInfo = ''">
              <img src="@/assets/img/close-mini.svg" alt="❌" />
            </button>
            <div class="info__title">{{ item.name }}</div>
            <div class="info__text">
              толщина, мм <br /><b>{{ item.thickness }}</b>
            </div>
            <div class="info__text">
              ширина рулонов, м <br />
              <div class="info__text-roll">
                <b
                  v-for="i in item.rollWidth.length"
                  :class="{ divider: i < item.rollWidth.length }"
                  :key="i"
                >
                  {{ item.rollWidth[i - 1].size }}
                </b>
              </div>
            </div>
            <span class="info__text"
              >Сопротивление раздиру, Н <br /><b>{{ item.resistance }}</b></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import MaterialsInfoIcon from '@/components/dump/MaterialsInfoIcon'

export default {
  components: { MaterialsInfoIcon },
  props: {
    isInModal: Boolean
  },
  data: () => ({
    pickedCategory: '',
    showInfo: ''
  }),
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_MATERIAL: 'UPDATE_SECTOR_MATERIAL',
      CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS: 'CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS'
    }),
    updateSectorMaterial() {
      this.clearStoredMinorResultRecommendedParams()
      if (this.isInModal) {
        // this.UPDATE_SECTOR_MATERIAL_PARAM({
        //   id: this.sectorId,
        //   param: 'id',
        //   val: this.material.id
        // })
        this.$emit('closeModal')
        this.$emit('runPost')
      }
      this.UPDATE_SECTOR_MATERIAL({
        id: this.sectorId,
        val: this.material.id
      })
    },
    close() {
      this.$emit('closeModal')
    },
    clearStoredMinorResultRecommendedParams() {
      if (this.isClearMinorResultNeed) {
        this.CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS(this.sectorId)
      }
    }
  },
  watch: {
    showInfo() {
      if (!this.isInModal) {
        if (this.showInfo) {
          this.$root.offBodyScroll()
        } else {
          this.$root.onBodyScroll()
        }
      }
    }
  },
  computed: {
    ...mapState({
      allMaterials: state => state.materials,
      categories: state => state.categories,
      mounting: state => state.mounting
    }),
    ...mapGetters({
      getIsClearMinorResultNeed: 'isClearMinorResultNeed'
    }),
    isClearMinorResultNeed() {
      return this.getIsClearMinorResultNeed(this.sectorId)
    },
    sectorId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorId)
    },
    material() {
      return this.sectorFromState.material
    },
    preparedMaterials() {
      const emptyId = 31
      const { sectorFromState, allMaterials } = this
      const sectorInsulation = sectorFromState.insulation
      // prettier-ignore
      return sectorInsulation.isEnabled
        ? allMaterials.filter(
          material =>
            material.insulationMethod.includes(sectorInsulation.data.method) &&
            material.insulationType.includes(sectorInsulation.data.type)
        )
        : allMaterials.filter(material =>
          material.insulationType.includes(emptyId)
        )
    },
    filteredAllMaterialsByMounting() {
      return this.preparedMaterials.filter(material =>
        material.mounting.includes(this.material.mounting)
      )
    },
    filteredCurrentMaterialsByCategory() {
      if (this.pickedCategory) {
        return this.filteredAllMaterialsByMounting.filter(
          material => material.category === this.pickedCategory
        )
      }
      return this.filteredAllMaterialsByMounting
    },
    isShowHint() {
      return !this.filteredCurrentMaterialsByCategory.length
    },
    hintText() {
      const isInsulationEnabled = this.sectorFromState.insulation.isEnabled
      const isCategorySelected = !!this.pickedCategory
      const beginningOfHint = 'Для выбранных настроек'
      const noSuitableMaterials = 'нет подходящих материалов.'
      const insulation = 'теплоизоляции'
      const dampCourse = 'гидроизоляции'
      const endOfString = isCategorySelected
        ? `в текущей категории ${noSuitableMaterials}`
        : noSuitableMaterials
      return isInsulationEnabled
        ? `${beginningOfHint} ${insulation} и ${dampCourse} ${endOfString}`
        : `${beginningOfHint} ${dampCourse} ${endOfString}`
    }
  }
}
</script>
<style lang="sass" scoped>
.dampCourse
  &__title
    +media((padding: (320: rem(48) rem(24) rem(30), 992: rem(82) 0 rem(21))))
    &.modal__title
      padding: rem(34) 0
      width: 100%
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      +media((padding: (320: rem(24), 992: rem(34) 0)))
  &__filter
    display: flex
    flex-direction: row
    align-items: center
    max-width: 100%
    width: 100%
    position: relative
    +media((padding: (320: 0 rem(24) 0, 992: 0 0 rem(34))))
    &:after
      height: rem(1)
      width: calc(100% - 48px)
      background: $grey
      content: ""
      position: absolute
      left: rem(24)
      bottom: rem(1)
      +media((display: (320: block, 768: none)))
    &-item
      font-style: normal
      font-weight: bold
      color: $light_black
      transition: .5s
      cursor: pointer
      position: relative
      text-align: left
      height: 100%
      +media((max-width: (320: rem(100), 768: unset)))
      +media((padding: (320: 0 0 rem(10), 768: rem(21) 0 rem(35))))
      +media((font-size: (320: rem(12), 768: rem(14))))
      +media((line-height: (320: rem(18), 768: rem(17))))
      +media((margin-right: (320: rem(20), 768: rem(30))))
      .name
        height: 100%
        position: relative
        +media((padding: (320: 0 0 rem(10), 768: rem(21) 0 rem(35))))
        &:after
          content: ""
          position: absolute
          left: 0
          bottom: 0
          height: rem(4)
          width: 0
          background: $red
          transition: .5s
      &:hover
        color: $black
        .name
          &:after
            width: 50%
      &.active
        color: $black
        .name
          &:after
            width: 100%
      input[type="radio"]
        position: absolute
        left: 0
        top: 0
        height: 0
        width: 0
        display: none
        opacity: 0
        pointer-events: none
  &__materials
    display: grid
    grid-column-gap: rem(1)
    grid-row-gap: rem(1)
    width: 100%
    +media((padding: (320: rem(32) rem(24) 0, 992: 0)))
    +media((grid-template-columns: (320: repeat(2, 1fr), 576: repeat(3, 1fr), 768: repeat(4, 1fr))))
    &-item
      background: $white
      position: relative
      &.shadow
        box-shadow: 0 0 0 rem(0.5) $ec
      @media(any-hover: hover)
        &:hover
          .dampCourse__materials-item--img
            &:after
              opacity: 1
          .dampCourse__materials-item--name
            color: $red
      &.active
        .dampCourse__materials-item--img
          &:after
            opacity: 1
        .dampCourse__materials-item--name
          color: $red
      &--label
        +media((padding: (320: rem(8) rem(13) rem(10), 992: rem(17) rem(20) rem(22))))
        cursor: pointer
        display: flex
        flex-direction: column
        position: relative
      &--img
        line-height: 0
        position: relative
        &:after
          +media((background-image: (320: url(~@/assets/img/tick-mini.svg), 768: url(~@/assets/img/tick.svg))))
          background-repeat: no-repeat
          top: 0
          bottom: 0
          left: 0
          right: 0
          content: ""
          position: absolute
          width: 100%
          height: 100%
          background-position: center
          line-height: 0
          opacity: 0
          pointer-events: none
          transition: .5s
          display: flex
          flex-direction: row
          justify-content: center
          align-items: center
        img
          max-width: 100%
          width: auto
          +media((height: (320: rem(110), 992: rem(120))))
          object-fit: cover
      &--name
        font-style: normal
        font-weight: 600
        line-height: rem(21)
        color: $black
        text-align: left
        width: 100%
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        +media((font-size: (320: rem(13), 992: rem(14))))
        +media((padding-top: (320: rem(16), 992: rem(21))))
        .showInfo
          &-btn
            line-height: 0
            cursor: help
            padding-left: rem(20)
            +media((position: (320: absolute, 992: relative)))
            +media((right: (320: rem(15), 992: unset)))
            +media((top: (320: rem(10), 992: unset)))
            svg
              .circle
                transition: .5s
                +media((opacity: (320: .3, 992: .8)))
                +media((fill: (320: $red, 992: $tn_gray)))
      .info
        background: $white
        box-shadow: 0 rem(4) rem(14) rgba(0, 0, 0, 0.16)
        border-radius: rem(4)
        +media((padding: (320: rem(17) rem(20), 992: rem(20) rem(20) rem(14))))
        display: flex
        flex-direction: column
        align-items: flex-start
        &__overlay
          z-index: 3
          +media((position: (320: fixed, 900: absolute)))
          +media((min-height: (320: 100vh, 900: 100%)))
          display: flex
          flex-direction: row
          justify-content: center
          align-items: center
          width: 100%
          top: 0
          left: 0
          transition: .5s
          +media((background: (320: rgba($black, .3), 900: transparent)))
          .info
            +media((min-height: (320: unset, 992: 100%)))
            +media((width: (320: rem(240), 992: 100%)))
            position: relative
        &__title
          font-style: normal
          font-weight: 600
          font-size: rem(14)
          line-height: rem(21)
          text-align: left
          padding-right: rem(40)
        &__text
          text-align: left
          font-style: normal
          font-weight: 500
          font-size: rem(12)
          line-height: rem(20)
          color: $light_black
          padding-top: rem(14)
          &-roll
            b
              padding-right: rem(16)
              position: relative
              &.divider
                &:after
                  content: "|"
                  color: $tn_gray
                  position: absolute
                  right: rem(8)
        .close
          background: none
          box-shadow: none
          border: none
          position: absolute
          right: 0
          top: 0
          line-height: 0
          cursor: pointer
          outline: none
          +media((padding: (320: rem(17), 992: rem(18))))
    input[type="radio"]
      position: absolute
      left: 0
      top: 0
      height: 0
      width: 0
      display: none
      opacity: 0
      pointer-events: none
.hint
  font-weight: 500
  font-size: rem(12)
  line-height: 150%
  text-align: left
  +media((padding: (0: 0 rem(24), 992: 0)))
  +media((margin-top: (0: rem(32), 992: 0)))
</style>
