<template>
  <div class="selection">
    <slot />
    <div class="selection__items">
      <selection-item
        :is-disabled="disabledItems.includes(item.value)"
        v-for="item in items"
        :item="item"
        :name="name"
        :current-type="currentType"
        :key-to-use="keyToUse"
        :key="item.id"
        @updateType="updateType"
      />
    </div>
  </div>
</template>

<script>
import SelectionItem from '@/components/dump/TypesSelection/SelectionItem'
export default {
  components: { SelectionItem },
  props: {
    name: {
      type: String,
      default: null,
      required: true
    },
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    currentType: {
      type: [String, Number],
      default: null
    },
    disabledItems: {
      type: Array,
      default: () => []
    },
    keyToUse: {
      type: String,
      default: 'id'
    }
  },
  name: 'TypesSelection',
  methods: {
    updateType(type) {
      this.$emit('updateType', type)
    }
  }
}
</script>

<style scoped lang="sass">
.selection
  &__items
    display: flex
    +media((flex-direction: (320: column, 768: row)))
</style>
