<template>
  <div class="total-value">
    <div class="title" v-if="!isError">
      <slot />
    </div>
    <div class="val" :class="{'val__error': isError}">
      <skeleton :width="90" v-if="isLoading" />
      <slot name="value" v-else />
    </div>
  </div>
</template>

<script>
import Skeleton from '@/components/dump/Skeleton'
export default {
  name: 'TotalValue',
  components: { Skeleton },
  props: {
    isLoading: Boolean,
    isError: Boolean
  }
}
</script>

<style scoped lang="sass">
.total-value
  text-align: left
  display: flex
  justify-content: flex-end
  gap: rem(20)
  align-items: center
  margin-top: rem(24)
  +media((margin-right: (320: 0, 600: rem(15), 800: rem(25), 992: rem(40))))
  font-style: normal
  line-height: 150%
  .title
    font-weight: 500
    +media((font-size: (0: rem(10), 360: rem(12))))
    color: $light-black
  .val
    color: $black
    font-weight: bold
    font-size: rem(14)
    &__error
      color: #E30713
</style>
