<template>
  <section>
    <Nav
      :step="sectorFromState.inAppStep"
      @goToLocation="goToLocation"
      @goToInsulation="goToInsulationFromNav"
      ref="Nav"
      v-if="!isInModal"
    />
    <div class="container">
      <div class="row">
        <div class="minor__title minor__title-btn" :class="{ modal__title: isInModal }">
          Основание кровли
          <button v-if="isInModal && !isShowFromModal" class="modal__title-btn" @click="close">
            <img src="@/assets/img/close-mini.svg" alt="❌" />
          </button>
          <DeleteBtn
            v-if="!isInModal && sectors.length > 1"
            @showDeleteConfirmation="showDeleteConfirmation"
          />
          <!--<button @click="deleteSector"
                  class="delete__btn" v-if="!isInModal && sectors.length > 1">
            <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 2.91667L18 5.29333M5.73105 3.60667L7.89615 1L12.1461 1.61333L13.349
            4.60333M12.467 12.1933L12.2264 19.5533M6.61321 12.1933L6.85377 19.63M1.56128
            7.74667H17.4386L16.6368 21.7C16.5566 22.9267 15.5141 24 14.2311 24H4.76883C3.40562 24
            2.44336 22.9267 2.36317 21.7L1.56128 7.74667Z" stroke="#999999" stroke-width="2"
                    stroke-miterlimit="22.9256" stroke-linecap="round" stroke-linejoin="round">
              </path>
            </svg>
          </button>-->
        </div>
        <div v-if="isInModal && !isShowFromModal" class="modal__header">
          <div class="modal__header-title">
            Тип основания<br />
            участка кровли
          </div>
          <div class="modal__header-info">
            <img
              :src="insulationMaterial.src"
              :alt="insulationMaterial.name"
              class="modal__header-info--img"
            />
            <div class="modal__header-info--text">
              <div class="name">
                {{ insulationMaterial.name }}
              </div>
              <div class="size" v-if="'size' in insulationMaterial">
                {{ insulationMaterial.size }}
              </div>
            </div>
          </div>
          <div class="modal__header-button">
            <button @click="isShowFromModal = !isShowFromModal">сменить</button>
          </div>
        </div>
        <div class="common__pane materials__pane" v-if="!isInModal || isShowFromModal">
          <div class="common__title">Выберите тип основания участка кровли</div>
          <div class="materials">
            <label
              class="materials__item"
              :class="{ active: sectorFromState.base.id === item.id }"
              v-for="item in insulationMaterials"
              :key="item.id"
            >
              <span class="materials__item-img">
                <img :src="item.src" :alt="item.name" width="100%" />
              </span>
              <span class="materials__item-title">{{ item.name }}</span>
              <span class="materials__item-size" v-if="'size' in item">{{ item.size }}</span>
              <input
                type="radio"
                v-model="sectorFromState.base.id"
                :value="item.id"
                @change="updateSectorBase"
              />
            </label>
          </div>
        </div>
        <transition name="fade">
          <div
            class="common__pane types__pane"
            :class="{ 'types__pane-modal': isInModal }"
            v-if="currentMaterial.hasTypes && sectorFromState.base.id !== null"
          >
            <div class="types__pane-head">
              <div class="common__title types__title">
                Выберите тип профлиста
                <span class="tooltip__btn">
                  <tippy :max-width="240">
                    <template #trigger>
                      <img src="@/assets/img/question.svg" alt="?" />
                    </template>
                    {{ tooltipText }}
                  </tippy>
                </span>
              </div>
              <double-fastener-switcher
                :is-checked="sectorFromState.base.type.isDoubleFastener"
                @updateStatus="updateSectorDoubleFastenerStatus"
              />
            </div>
            <div class="types">
              <label
                class="types__item"
                v-for="item in currentMaterialTypes"
                :class="{
                  active: sectorFromState.base.type.id === item.id
                }"
                :key="item.id"
              >
                <!-- disabled: sectorFromState.base.type.isCustom -->
                <span class="types__item-bg">
                  <span class="types__item-size">{{ item.size }} мм</span>
                  <span class="types__item-img">
                    <img :src="item.src" :alt="item.name" />
                  </span>
                </span>
                <span class="types__item-title">{{ item.name }}</span>
                <input
                  type="radio"
                  v-model="sectorFromState.base.type.id"
                  :value="item.id"
                  @change="updateSectorBaseType"
                />
                <!-- :disabled="isCustomBase" -->
              </label>
              <div
                class="types__item types__item--custom"
                @click="updateSectorBaseTypeMod(true)"
                :class="{ active: isCustomBase }"
              >
                <div class="types__item-bg">
                  <custom-corrugations-step
                    ref="step"
                    :step="sectorFromState.base.type.size"
                    @updateStep="updateStep"
                    :is-enabled="isCustomBase"
                  />
                  <span class="types__item-img">
                    <img src="@/assets/img/insulation-materials/types/custom.png" alt="custom" />
                  </span>
                </div>
                <span class="types__item-title">свой вариант</span>
              </div>
            </div>
            <!-- <div class="types__custom" v-if="currentMaterial.hasTypes">
              <div class="title__withToggler">
                <div class="common__title">Свой вариант</div>
                <div class="wrapper">
                  <span class="checkbox-toggler">
                    <span class="checkbox-track"></span>
                    <input
                      type="checkbox"
                      v-model="sectorFromState.base.type.isCustom"
                      @change="updateSectorBaseTypeMod"
                    />
                    <span class="toggler"></span>
                  </span>
                </div>
              </div>
              <div class="types__custom-input--wrapper">
                <div class="types__custom-input--text">
                  Шаг между гофрами (А) мм
                  <span class="tooltip__btn">
                    <tippy :max-width="240">
                      <template #trigger>
                        <img src="@/assets/img/question.svg" alt="?" />
                      </template>
                      <img src="@/assets/img/scheme.png" alt="scheme" />
                    </tippy>
                  </span>
                </div>
                <input
                  type="number"
                  class="types__custom-input"
                  v-model.number="sectorFromState.base.type.size"
                  :disabled="!sectorFromState.base.type.isCustom"
                  @change="updateSectorBaseTypeSize"
                />
              </div>
            </div> -->
          </div>
        </transition>
        <load-bearing-capacity
          :is-enabled="isLoadBearingEnabled"
          :value="loadBearing"
          @updateStatus="updateLoadBearingStatus"
          @updateValue="updateLoadBearingValue"
        />
        <button
          v-if="isInModal && isShowFromModal"
          :disabled="isDisabled"
          class="modal__save"
          @click="close"
        >
          Применить
        </button>
        <div class="buttons__nav wizard__buttons-nav" v-if="!isInModal">
          <router-link to="/location" class="common__button-prev">
            <img src="@/assets/img/common-arrow-prev.svg" alt="←" />
            <span>Ветровой район</span>
          </router-link>
          <button @click="goToInsulation" class="common__button-next" :disabled="isDisabled">
            Верхний слой теплоизоляции
            <img src="@/assets/img/common-arrow-next.svg" alt="→" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import Nav from '@/components/smart/Nav.vue'
import DeleteBtn from '@/components/smart/DeleteBtn.vue'
import DoubleFastenerSwitcher from '@/components/smart/DoubleFastenerSwitcher'
import CustomCorrugationsStep from '@/components/dump/CustomСorrugationsStep'
import LoadBearingCapacity from '@/components/dump/LoadBearingCapacity'

const tooltipText =
  'Выберите тип профлиста из представленного списка ниже или укажите собственный тип'

export default {
  props: {
    isInModal: Boolean,
    insulationMaterial: Object
  },
  data: () => ({
    isShowFromModal: false,
    step: ''
  }),
  components: {
    LoadBearingCapacity,
    CustomCorrugationsStep,
    DoubleFastenerSwitcher,
    Nav,
    DeleteBtn
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_BASE: 'UPDATE_SECTOR_BASE',
      UPDATE_SECTOR_BASE_TYPE: 'UPDATE_SECTOR_BASE_TYPE',
      UPDATE_SECTOR_BASE_TYPE_MOD: 'UPDATE_SECTOR_BASE_TYPE_MOD',
      UPDATE_SECTOR_BASE_TYPE_SIZE: 'UPDATE_SECTOR_BASE_TYPE_SIZE',
      CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS: 'CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS',
      UPDATE_SECTOR_BASE_TYPE_PARAM: 'UPDATE_SECTOR_BASE_TYPE_PARAM',
      UPDATE_SECTOR_LOAD_BEARING_PARAM: 'UPDATE_SECTOR_LOAD_BEARING_PARAM',
      DELETE_SECTOR: 'DELETE_SECTOR'
    }),
    ...mapActions({
      updateSectorDoubleFastenerStatusAction: 'updateSectorDoubleFastenerStatus'
    }),
    updateSectorDoubleFastenerStatus(status) {
      this.updateSectorDoubleFastenerStatusAction({
        sectorIndex: this.sectorId,
        status
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateLoadBearingStatus(status) {
      this.UPDATE_SECTOR_LOAD_BEARING_PARAM({
        sectorIndex: this.sectorId,
        param: 'isCalculate',
        val: status
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateLoadBearingValue(val) {
      this.UPDATE_SECTOR_LOAD_BEARING_PARAM({
        sectorIndex: this.sectorId,
        param: 'value',
        val
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateStep(val) {
      this.UPDATE_SECTOR_BASE_TYPE_PARAM({
        sectorIndex: this.sectorId,
        param: 'size',
        val
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateSectorBase() {
      this.UPDATE_SECTOR_BASE({
        id: this.sectorId,
        material: this.sectorFromState.base.id,
        hasTypes: this.currentMaterial.hasTypes
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateSectorBaseType() {
      this.UPDATE_SECTOR_BASE_TYPE({
        id: this.sectorId,
        material: this.sectorFromState.base.type.id,
        size: this.baseTypeSize
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateSectorBaseTypeMod(value) {
      this.UPDATE_SECTOR_BASE_TYPE_MOD({
        id: this.sectorId,
        mod: value
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateSectorBaseTypeSize() {
      this.UPDATE_SECTOR_BASE_TYPE_SIZE({
        id: this.sectorId,
        size: this.sectorFromState.base.type.size
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    clearStoredMinorResultRecommendedParams() {
      if (!this.isInModal) {
        if (this.isClearMinorResultNeed) {
          this.CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS(this.sectorId)
        }
      }
    },
    showDeleteConfirmation() {
      this.$emit('showDeleteConfirmation')
    },
    close() {
      this.$emit('closeModal')
      // this.$emit('runPost')
    },
    goToLocation() {
      this.$emit('goToLocation')
    },
    goToInsulation() {
      // dataLayer.push({ event: 'calc-roofload-step3' })
      this.$emit('goToInsulation')
    },
    goToInsulationFromNav() {
      if (!this.isDisabled) {
        this.$emit('goToInsulation')
      }
    }
  },
  computed: {
    ...mapState({
      insulationMaterials: state => state.insulationMaterials,
      sectors: state => state.sectors
    }),
    ...mapGetters({
      getIsClearMinorResultNeed: 'isClearMinorResultNeed'
    }),
    isClearMinorResultNeed() {
      return this.getIsClearMinorResultNeed(this.sectorId)
    },
    sectorId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorId)
    },
    isDisabled() {
      return this.currentMaterial.hasTypes ? this.disableWithTypes : this.disableWithoutTypes
    },
    disableWithTypes() {
      return !(this.sectorFromState.base.id !== null && this.sectorFromState.base.type.size > 0)
    },
    disableWithoutTypes() {
      return this.sectorFromState.base.id === null
    },
    currentMaterial() {
      return this.sectorFromState.base.id === null
        ? this.insulationMaterials[0]
        : this.insulationMaterials.find(p => p.id === this.sectorFromState.base.id)
    },
    currentMaterialTypes() {
      const { sectorFromState, currentMaterial } = this
      const isDoubleFastenersEnabled = sectorFromState.base.type.isDoubleFastener
      return isDoubleFastenersEnabled
        ? currentMaterial.types.filter(type => type.isDoubleFasteners)
        : currentMaterial.types
    },
    isCustomBase() {
      return this.sectorFromState.base.type.isCustom
    },
    isLoadBearingEnabled() {
      return this.sectorFromState.base.loadBearingCapacity.isCalculate
    },
    loadBearing() {
      return this.sectorFromState.base.loadBearingCapacity.value
    },
    // eslint-disable-next-line
    baseTypeSize() {
      if (this.currentMaterial.hasTypes) {
        return this.sectorFromState.base.type.id === null
          ? this.currentMaterial.types[0].size
          : this.currentMaterial.types.find(p => p.id === this.sectorFromState.base.type.id).size
      }
    },
    tooltipText: () => tooltipText
  }
}
</script>
<style lang="sass" scoped>
.modal
  &__title
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    +media((padding: (320: rem(24), 992: rem(34) rem(40))))
  &__header
    display: grid
    width: 100%
    +media((padding: (320: 0 rem(24) rem(16) rem(34), 992: 0 rem(40) rem(34))))
    +media((grid-template-columns: (320: 1fr, 576: 7fr 10fr 5fr, 768: 7fr 12fr 3fr)))
    box-shadow: inset 0 rem(-1) 0 $ec
    &-title
      font-style: normal
      font-weight: bold
      color: $black
      text-align: left
      display: flex
      flex-direction: row
      align-items: center
      +media((padding-right: (320: rem(10), 992: 0)))
      +media((font-size: (320: rem(15), 992: rem(18))))
      +media((line-height: (320: rem(20), 992: rem(29))))
      +media((padding-bottom: (320: rem(16), 992: 0)))
      br
        +media((display: (320: none, 992: inline-block)))
    &-info
      display: flex
      flex-direction: row
      justify-content: flex-start
      +media((padding-bottom: (320: rem(16), 992: 0)))
      &--img
        width: rem(80)
        height: rem(68)
        object-fit: cover
      &--text
        text-align: left
        padding-left: rem(20)
        .name
          font-style: normal
          font-weight: bold
          font-size: rem(14)
          line-height: rem(18)
          color: $black
          padding: rem(8) 0 rem(5)
          text-transform: uppercase
        .size
          font-style: normal
          font-weight: 500
          font-size: rem(12)
          line-height: rem(22)
          color: $light_black
    &-button
      display: flex
      flex-direction: row
      align-items: center
      button
        font-family: $main-font
        font-style: normal
        font-weight: 600
        font-size: rem(14)
        line-height: rem(16)
        text-align: center
        border: rem(1) solid $red
        color: $red
        background: $white
        box-shadow: none
        outline: none
        transition: .5s
        cursor: pointer
        width: 100%
        border-radius: rem(4)
        height: rem(40)
        @media(any-hover: hover)
          &:hover
            background: $red-hover
            color: $white
            border-color: $red-hover
  &__save
    color: $white
    font-style: normal
    font-weight: bold
    font-size: rem(16)
    line-height: rem(20)
    background: $red
    transition: .5s
    border-radius: rem(4)
    width: rem(160)
    height: rem(51)
    text-align: center
    cursor: pointer
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    border: none
    box-shadow: none
    outline: none
    margin: auto
    @media(any-hover: hover)
      &:hover
        background: $red-hover
    &:disabled
      background: $grey

.tooltip__btn
  line-height: 0
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-left: rem(10)
  cursor: help
.materials
  display: grid
  color: $black
  +media((padding: (320: 0, 992: 0 rem(20))))
  +media((grid-column-gap: (320: rem(26), 992: rem(40))))
  +media((grid-template-columns: (320: repeat(2, 1fr), 700: repeat(4, 1fr))))
  grid-row-gap: rem(32)
  &__pane
    z-index: 2
  &__item
    display: flex
    flex-direction: column
    text-align: left
    cursor: pointer
    &.active
      .materials__item-size, .materials__item-title
        color: $red
      .materials__item-img
        img
          transform: scale(1.2)
        &:before
          opacity: 1
    &-img
      line-height: 0
      overflow: hidden
      position: relative
      &:before
        +media((content: (320: url(~@/assets/img/tick-mini.svg), 768: url(~@/assets/img/tick.svg))))
        position: absolute
        top: 0
        left: 0
        line-height: 0
        opacity: 0
        pointer-events: none
        transition: .5s
        z-index: 2
        width: 100%
        height: 100%
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        background: rgba(92, 92, 92, 0.2)
      img
        transform: scale(1)
        transition: .5s
        z-index: 1
    @media(any-hover: hover)
      &:hover
        .materials__item-size, .materials__item-title
          color: $red
        .materials__item-img
          img
            transform: scale(1.2)
          &:before
            opacity: 1
    &-title
      font-style: normal
      font-weight: bold
      font-size: rem(14)
      line-height: rem(18)
      text-transform: uppercase
      transition: .5s
      +media((padding: (320: rem(16) 0 rem(8), 992: rem(25) 0 rem(5))))
    &-size
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(22)
      color: $light_black
      transition: .5s
    input[type="radio"]
      position: absolute
      left: 0
      top: 0
      height: 0
      width: 0
      display: none
      opacity: 0
      pointer-events: none
.types
  display: grid
  grid-column-gap: rem(4)
  align-items: flex-start
  color: $black
  // +media((margin-bottom: (320: rem(40), 992: rem(42))))
  +media((grid-template-columns: (320: repeat(2, 1fr), 500: repeat(3, 1fr), 700: repeat(4, 1fr), 800: repeat(5, 1fr))))
  grid-row-gap: rem(24)
  &__custom
    padding-top: rem(40)
    box-shadow: inset 0 rem(1) 0 $ec
  &__pane
    margin-top: rem(34)
    +media((padding: (320: rem(32) rem(24), 992: rem(40))))
    z-index: 1
    &-head
      display: flex
      justify-content: space-between
      margin-bottom: rem(40)
      +media((aling-items: (768: center)))
      +media((flex-direction: (320: column, 768: row)))
      .types
        &__title
          padding: 0
          +media((margin-bottom: (320: rem(20), 768: 0)))
          // margin-right: rem(20)
    &-modal
      margin: 0
      +media((padding: (320: rem(32) rem(24), 992: rem(34) rem(40))))
  &__title
    display: flex
    flex-direction: row
    align-items: center
  &__item
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    cursor: pointer
    transition: .5s
    &.disabled
      pointer-events: none
      opacity: .5
    input[type="radio"]
      position: absolute
      left: 0
      top: 0
      height: 0
      width: 0
      display: none
      opacity: 0
      pointer-events: none
    @media(any-hover: hover)

      &:hover
        &:not(.types__item--custom)
          .types__item-bg
            &:before
              opacity: 1
        .types__item-title
          color: $red
    &:not(.types__item--custom)
      &.active
        .types__item-bg
          &:before
            opacity: 1
    &.active
      .types__item-title
        color: $red
    &--custom
      cursor: pointer
      @media(any-hover: hover)
        &:hover
          ::v-deep .input-wrapper
            box-shadow: 0 rem(1) rem(4) rgba(51, 51, 51, 0.3)
      &.active
        @media(any-hover: hover)
          &:hover
            .types__item-bg
              &:before
                opacity: 0
    &-size
      font-style: normal
      font-weight: 600
      font-size: rem(12)
      line-height: rem(30)
      color: $tn_gray_plus
    &-title
      font-style: normal
      font-weight: bold
      font-size: rem(14)
      letter-spacing: 0.04em
      text-transform: uppercase
      padding-top: rem(18)
      transition: .5s
      +media((padding: (320: rem(8), 992: rem(18))))
    &-img
      img
        width: 100%
    &-bg
      background: $semi-white
      // padding: rem(18) 0
      display: flex
      justify-content: center
      align-items: center
      flex-direction: column
      padding: rem(18) 0
      +media((min-height: (320: rem(128), 500: rem(136))))
      position: relative
      // min-height: rem(125)
      width: 100%
      &:before
        background-image: url(~@/assets/img/tick-mini.svg)
        content: ""
        position: absolute
        background-repeat: no-repeat
        background-position: center
        top: 0
        left: 0
        line-height: 0
        opacity: 0
        pointer-events: none
        transition: .5s
        width: 100%
        height: 100%
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        background-color: rgba($black, .2)
  // &__custom
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    flex-wrap: wrap
    &-input
      background: #FFFFFF
      border: rem(1) solid $grey
      box-sizing: border-box
      border-radius: rem(4)
      font-family: $main-font
      width: rem(80)
      height: rem(34)
      font-style: normal
      font-weight: bold
      font-size: rem(18)
      line-height: rem(22)
      text-align: center
      outline: none
      transition: .5s
      margin-left: rem(54)
      &:hover
        @extend .common__input-hover
      &:disabled
        pointer-events: none
        opacity: .3
      &--text
        font-family: Montserrat
        font-style: normal
        font-weight: 500
        font-size: rem(12)
        line-height: rem(18)
        display: flex
        flex-direction: row
        align-items: center
      &--wrapper
        display: flex
        flex-direction: row
        align-items: center
.fade-enter-active, .fade-leave-active
  transition: .5s
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
  margin-top: rem(-400)
  opacity: 0
</style>
