<template>
  <span class="skeleton-box" :style="styles"></span>
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    width: {
      type: Number
    }
  },
  computed: {
    styles() {
      return {
        width: (this.width && `${this.width}px`) || '80%'
      }
    }
  }
}
</script>

<style scoped lang="sass">
.skeleton-box
  height: rem(20)
  position: relative
  overflow: hidden
  display: block
  background-color: $ec
  border-radius: rem(4)
  &::after
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    transform: translateX(-100%)
    background-image: linear-gradient(90deg, rgba($white, 0) 0, rgba($white, 0.2) 20%, rgba($white, 0.5) 60%, rgba($white, 0))
    animation: skeleton 2s infinite
    content: ''

@keyframes skeleton
  100%
    transform: translateX(100%)
</style>
