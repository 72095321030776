<template>
  <div class="step-wrapper">
    <label class="input-wrapper" for="corrugations-step">
      <input
        type="number"
        class="input"
        id="corrugations-step"
        v-if="isEnabled"
        v-model.number="computedStep"
        ref="input"
      />
      <span class="plug" v-else></span>
      <span class="units">мм</span>
    </label>
    <img src="@/assets/img/tick--custom.svg" v-if="isEnabled" class="tick" alt="" />
  </div>
</template>

<script>
import { isValidNumber } from '@/utils'

export default {
  props: {
    step: {
      type: Number,
      default: 0
    },
    isEnabled: {
      type: Boolean,
      required: true
    }
  },
  name: 'CustomCorrugationsStep',
  // watch: {
  //   isEnabled(val) {
  //     if (val) this.$nextTick().then(this.$refs.input.focus())
  //   }
  // },
  computed: {
    computedStep: {
      get() {
        return this.step
      },
      set(val) {
        if (isValidNumber(val)) {
          this.$emit('updateStep', val)
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.step-wrapper
  display: flex
  align-items: center
  justify-content: center
  margin: 0 auto rem(8)
  .tick
    margin-left: rem(6)
    +media((max-width: (320: rem(20), 360: unset)))
.input-wrapper
  display: flex
  align-items: center
  transition: .5s
  background: $white
  border: rem(1) solid $tn_gray
  box-sizing: border-box
  border-radius: rem(4)
  max-width: rem(96)
  flex: 0 0 rem(96)
  min-height: rem(40)
  .input, .units, .plug
    width: 50%
    font-family: $main-font
    font-style: normal
    font-weight: 600
    font-size: rem(14)
    line-height: rem(17)
    display: flex
    align-items: center
    text-align: center
    color: $black
  .input, .plug
    outline: none
    border: none
    background: none
    padding: 0 rem(2)
    text-align: right
  .input
    min-height: rem(38)
    text-overflow: ellipsis
  .units
    text-align: left
  .plug
    display: block
    min-height: unset
</style>
