<template>
  <section>
    <Nav
      :step="sectorFromState.inAppStep"
      @goToBase="goToBase"
      @goToLocation="goToLocation"
      @goToInsulation="goToInsulation"
      @goToDampCourse="goToDampCourse"
      @goToSector="goToSector"
      @addFromNav="addFromNav"
      ref="Nav"
    />
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="fullPage"
    ></loading>
    <div class="container">
      <div class="row">
        <div class="minor__title minor__title-btn">
          Промежуточный расчет
          <DeleteBtn v-if="sectors.length > 1" @showDeleteConfirmation="showDeleteConfirmation" />
        </div>
      </div>
      <div class="row">
        <div class="common__pane">
          <div
            class="minorResult__materials"
            :class="{ 'minorResult__materials--error': !isSomeZoneHasOptions }"
          >
            <div class="minorResult__materials-item">
              <div class="title">
                {{ base }}
              </div>
              <div
                class="minorResult__materials-item--single"
                @click="isShowModalBase = !isShowModalBase"
              >
                <div class="name">
                  <span class="name__mobile">{{ base }}</span>
                  <br />
                  {{ getInsulationMaterial(sectorFromState.base.id).name }}
                  {{ getInsulationMaterial(sectorFromState.base.id).size }}
                  <span v-if="sectorFromState.base.hasOwnProperty('type')">
                    ({{ sectorFromState.base.type.size }}мм)
                  </span>
                </div>
                <button class="btn">
                  <img src="@/assets/img/change.svg" alt="change" />
                </button>
              </div>
            </div>
            <div class="minorResult__materials-item">
              <div class="title">
                {{ dampCourse }}
              </div>
              <div
                class="minorResult__materials-item--single"
                @click="isShowModalMaterials = !isShowModalMaterials"
              >
                <div class="name">
                  <span class="name__mobile">{{ dampCourse }}</span>
                  <br />
                  {{ getDampCourseMaterial(sectorFromState.material.id).name }}
                </div>
                <button class="btn">
                  <img src="@/assets/img/change.svg" alt="change" />
                </button>
              </div>
            </div>
          </div>
          <div class="zones__wrapper" v-if="minorResult && isSomeZoneHasOptions">
            <div class="zones__titles-mob">
              <div class="zones__titles-mob--item">
                <div class="tooltip__btn">
                  <tippy :max-width="240" placement="right">
                    <template #trigger>
                      <img src="@/assets/img/question.svg" alt="?" />
                    </template>
                    <div class="d-flex flex-column">
                      <span class="zones__titles-mob--tooltip">Зона C: угловая</span>
                      <span class="zones__titles-mob--tooltip">Зона D: парапетная</span>
                      <span class="zones__titles-mob--tooltip">Зона E: центральная</span>
                    </div>
                  </tippy>
                </div>
                Ширина<br />
                рулона гидро<span>-</span><br />изоляции
              </div>
              <mobile-title
                v-for="[key, value] in zoneOptionsWithoutRollWidth"
                :item="{ key, value }"
                :key="key"
              />
            </div>
            <template v-if="isSomeZoneHasOptions && minorResult">
              <div class="zones">
                <zone
                  v-for="zone in minorResult"
                  :zone="zone"
                  :stored-recommended="getStoredRecommendedValueByZone(zone.name)"
                  :key="`zone--${zone.name}`"
                  :error-message="errorMessage"
                  @updateValue="updateSectorSize($event, zone.name)"
                  @updateStatus="updateDoubleFastener($event, zone.name)"
                >
                  <span class="zones__item-title--type">{{ zonesNames[zone.name] }}</span> зона
                  {{ zone.name }}
                </zone>
              </div>
            </template>
          </div>
          <div class="minorResult__error" v-if="!isSomeZoneHasOptions && !isLoading">
            {{ errorMessage }}
          </div>
          <div class="summary" v-if="isSomeZoneHasOptions">
            <total-value v-if="fastenersAverage" :is-loading="isAverageParamsLoading">
              Среднее к-во крепежа на 1 м²
              <template #value>
                {{ fastenersAverage }}
              </template>
            </total-value>
            <total-value
              v-if="waterproofingGlueCapacityValue"
              :is-error="hasWaterproofingGlueCapacityError"
            >
              Несущая способность
              <template #value>
                {{ waterproofingGlueCapacityValue }}
              </template>
            </total-value>
          </div>
        </div>
        <div class="common__pane plan__wrapper">
          <div class="plan__wrapper-title">План кровли</div>
          <div class="plan__figure">
            <svg-wrapper>
              <component
                :is="currentFigureComponent"
                :current="buildingData"
                :buildingHeight="buildingData.height"
                :rollWidthOptions="rollWidthOption"
              ></component>
            </svg-wrapper>
          </div>
        </div>
        <div class="common__pane plan__wrapper plan__wrapper-algorithm">
          <div class="title">
            <div class="common__title">
              {{ getWidthAlgorithmTitle }}
            </div>
            <switcher
              :is-toggled="Boolean(isDependOnRollsSize)"
              id="algorithm"
              @updateStatus="switchAlgorithmWidthCalculating" />
          </div>
        </div>
        <div class="buttons__nav wizard__buttons-nav">
          <button @click="goToSector" class="common__button-prev">
            <img src="@/assets/img/common-arrow-prev.svg" alt="←" />
            <span>Параметры участка</span>
          </button>
          <button @click="goToResult" class="common__button-next special">
            Рассчитать все участки
            <img src="@/assets/img/calc.svg" alt="calc" />
          </button>
        </div>
        <div class="wizard__buttons-nav--result">
          <button class="common__button-result" @click="addSector">
            <img src="@/assets/img/big-plus.svg" alt="+" />
            Добавить новый участок
          </button>
          <div class="text">Параметры текущего участка сохранятся и добавятся в общий расчет</div>
        </div>
      </div>
      <transition name="slide">
        <div class="materials__modal" v-if="isShowModalBase">
          <div class="materials__modal-body">
            <Base
              isInModal
              :insulationMaterial="getInsulationMaterial(sectorFromState.base.id)"
              @closeModal="closeModalBase"
              @runPost="fetchMinorResult(true)"
            />
          </div>
        </div>
      </transition>
      <div class="materials__modal-mask" v-if="isShowModalBase" @click="closeModalBase"></div>
      <transition name="slide">
        <div class="materials__modal" v-if="isShowModalMaterials">
          <div class="materials__modal-body">
            <div class="container">
              <Materials isInModal @closeModal="closeModalMaterials" @runPost="fetchMinorResult" />
            </div>
          </div>
        </div>
      </transition>
      <div
        class="materials__modal-mask"
        v-if="isShowModalMaterials"
        @click="isShowModalMaterials = !isShowModalMaterials"
      ></div>
    </div>
  </section>
</template>
<script>
import { formsComponents, getZoneOptionsWithoutRollWidth } from '@/utils'
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { getFastenersAverage, getMinorResult } from '@/api'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import Materials from '@/components/smart/Materials.vue'
import Base from '@/components/smart/Base.vue'
import Nav from '@/components/smart/Nav.vue'
import DeleteBtn from '@/components/smart/DeleteBtn.vue'
import Zone from '@/components/dump/MinorResultTable/Zone'
import SvgWrapper from '@/components/dump/Figures/SvgWrapper'
import TotalValue from '@/components/dump/MinorResultTable/TotalValue'
import MobileTitle from '@/components/dump/MinorResultTable/MobileTitle'
import ZoneError from '@/components/dump/MinorResultTable/ZoneError'
import Switcher from '@/components/dump/switcher'

const errorMessage = 'Для выбранных параметров нет возможности сделать расчет'

const base = 'Основание кровли'
const dampCourse = 'Гидроизоляция'

const zonesNames = {
  C: 'Угловая',
  D: 'Парапетная',
  E: 'Центральная'
}

const algorithmCalculatingWidthZone = {
  true: 'Ширина ветровых зон рассчитывается в зависимости от размеров рулонов гидроизоляции',
  false: 'Ширина ветровых зон рассчитывается без учёта размеров рулонов гидроизоляции'
}

export default {
  data: () => ({
    isShowModalMaterials: false,
    isShowModalBase: false,
    fullPage: true,
    isLoading: false,
    isAverageParamsLoading: false,
    rollWidthOption: null,
    rollWidthIndex: null
  }),
  components: {
    ZoneError,
    MobileTitle,
    TotalValue,
    SvgWrapper,
    Zone,
    Materials,
    Base,
    Nav,
    DeleteBtn,
    Switcher,
    Loading
  },
  watch: {
    isShowModalMaterials() {
      if (this.isShowModalMaterials) {
        this.$root.offBodyScroll()
      } else {
        this.$root.onBodyScroll()
      }
    },
    isShowModalBase() {
      if (this.isShowModalBase) {
        this.$root.offBodyScroll()
      } else {
        this.$root.onBodyScroll()
      }
    }
  },
  methods: {
    ...mapMutations({
      SET_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS: 'SET_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS',
      UPDATE_SECTOR_MINOR_RESULT_RECOMMENDED_PARAM: 'UPDATE_SECTOR_MINOR_RESULT_RECOMMENDED_PARAM',
      CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS: 'CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS',
      UPDATE_SECTOR_SIDE_DOUBLE_FASTENER: 'UPDATE_SECTOR_SIDE_DOUBLE_FASTENER',
      UPDATE_STATE_OBJECT: 'UPDATE_STATE_OBJECT',
      UPDATE_SECTOR_DEPENDENCY_ON_ROLLS_SIZE: 'UPDATE_SECTOR_DEPENDENCY_ON_ROLLS_SIZE',
      UPDATE_SECTOR_DEPENDENCY_ON_ROLLS_OPTIONS: 'UPDATE_SECTOR_DEPENDENCY_ON_ROLLS_OPTIONS',
      UPDATE_SECTOR_PARAM: 'UPDATE_SECTOR_PARAM'
    }),
    ...mapActions({
      saveMinorResults: 'saveMinorResults'
    }),
    getStoredRecommendedValueByZone(zone) {
      return this.storedMinorResult[zone]
    },
    showDeleteConfirmation() {
      this.$emit('showDeleteConfirmation')
    },
    goToLocation() {
      this.$emit('goToLocation')
    },
    goToBase() {
      this.$emit('goToBase')
    },
    goToInsulation() {
      this.$emit('goToInsulation')
    },
    goToSector() {
      this.$emit('goToSector')
    },
    goToDampCourse() {
      this.$emit('goToDampCourse')
    },
    addFromNav() {
      this.$emit('addFromNav')
    },
    goToResult() {
      this.updateSectorImage()
      this.$router.push('/result')
    },
    getInsulationMaterial(id) {
      return this.insulationMaterials.find(material => material.id === id)
    },
    getDampCourseMaterial(id) {
      return this.dampCourseMaterials.find(material => material.id === id)
    },
    closeModalMaterials() {
      this.isShowModalMaterials = !this.isShowModalMaterials
    },
    closeModalBase() {
      if (!this.isDisabled) {
        this.fetchMinorResult(true)
        this.isShowModalBase = !this.isShowModalBase
      }
    },
    fetchMinorResult(isNeedToClearStoredMinorResult = false) {
      this.isLoading = true
      if (isNeedToClearStoredMinorResult) {
        this.CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS(this.sectorId)
      }
      this.getMinorResultByApi()
    },
    getMinorResultByApi() {
      getMinorResult(this.sectorFromState, this.location)
        .then(response => {
          if (process.env.NODE_ENV === 'development') {
            console.log(response.data)
          }
          this.SET_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS({
            sectorIndex: this.sectorId,
            zones: response.data.zones
          })
          const minorResult = response.data.zones.map(el => {
            return {
              ...el,
              isDoubleFastener: this.sectorFromState.base?.type?.isDoubleFastener,
              doubleFastenerBySide: this.sectorFromState.base?.type?.doubleFastenerBySide
            }
          })
          this.updateRollWidthOptions(null, response.data)
          const fastenersAverage = response.data.fastenersAverage
            ? response.data.fastenersAverage
            : null
          const waterproofingGlueCapacity = {
            value: response.data.waterproofing_glue_capacity ?? null,
            error: Boolean(response.data.waterproofing_glue_capacity_error ?? null)
          }

          this.saveMinorResults({ index: this.sectorId, label: 'minorResult', data: minorResult })
          this.saveMinorResults({ index: this.sectorId, label: 'fastenersAverage', data: fastenersAverage })
          this.saveMinorResults({ index: this.sectorId, label: 'waterproofingGlueCapacity', data: waterproofingGlueCapacity })

          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    addSector() {
      this.updateSectorImage()
      this.$emit('addSector')
    },
    updateSectorImage() {
      this.UPDATE_SECTOR_PARAM({
        id: this.sectorId,
        param: 'sectorImage',
        val: btoa(document.querySelector('.plan__figure').outerHTML)
      })
    },
    updateRollWidthOptions(inputOptions = null, data = null) {
      const maxWidthForRollSizeDependency = 2
      const recommendedIndex = this.getStoredRecommendedValueByZone('D')
      const index = this.rollWidthIndex || recommendedIndex || 0
      const width = inputOptions
        ? this.minorResult.find((zone) => zone.name === inputOptions.name)?.options[inputOptions.val].rollWidth
        : data.zones.find((zone) => zone.name === 'D').options[index].rollWidth

      if (inputOptions) {
        this.rollWidthIndex = inputOptions.val
      }

      if (this.isDependOnRollsSize &&
        width >= maxWidthForRollSizeDependency &&
        Object.values(this.buildingData.params).find((p) => p <= maxWidthForRollSizeDependency)
      ) {
        this.switchAlgorithmWidthCalculating(false)
      }

      this.rollWidthOption = this.isDependOnRollsSize
        ? {
          ...this.rollWidthOption,
          width,
          overlap: this.getMaterialOverlap,
          distance: this.getMaterialFastenerDistance(width)
        }
        : null
      this.UPDATE_SECTOR_DEPENDENCY_ON_ROLLS_OPTIONS({
        sectorIndex: this.sectorId,
        options: this.rollWidthOption
      })
    },
    updateSectorSize(val, name) {
      this.UPDATE_SECTOR_MINOR_RESULT_RECOMMENDED_PARAM({
        sectorIndex: this.sectorId,
        param: name,
        val
      })
      this.recalculateAverageParams()

      if (this.isDependOnRollsSize && name === 'D') {
        this.updateRollWidthOptions({ name, val })
      }
    },
    updateDoubleFastener(val, name) {
      this.UPDATE_SECTOR_SIDE_DOUBLE_FASTENER({
        sectorIndex: this.sectorId,
        param: name,
        val
      })
      this.getMinorResultByApi()
    },
    recalculateAverageParams() {
      const isNeedRecalculateAverageParams = !!this.fastenersAverage
      if (isNeedRecalculateAverageParams) {
        this.isAverageParamsLoading = true
        const estimated = Object.fromEntries(
          Object.entries({ ...this.storedMinorResult }).map(([key, value]) => {
            const currentZone = this.minorResult.find(zone => zone.name === key)
            const estimatedValue =
              (currentZone.options && currentZone.options[value].estimated) || 0
            return [key, estimatedValue]
          })
        )
        const widths = Object.fromEntries(
          Object.entries({ ...this.storedMinorResult }).map(([key, value]) => {
            const currentZone = this.minorResult.find(zone => zone.name === key)
            const width =
              (currentZone.options && currentZone.options[value].rollWidth) || 0
            return [key, width]
          })
        )
        getFastenersAverage(this.sectorFromState.building, estimated, widths, this.sectorFromState.material.id, this.isDependOnRollsSize)
          .then(response => {
            this.saveMinorResults({ index: this.sectorId, label: 'fastenersAverage', data: response.data })
            this.isAverageParamsLoading = false
          })
          .catch(() => {
            this.isAverageParamsLoading = false
          })
      }
    },
    switchAlgorithmWidthCalculating(value = null) {
      this.UPDATE_SECTOR_DEPENDENCY_ON_ROLLS_SIZE({
        sectorIndex: this.sectorId,
        val: value === null ? !this.isDependOnRollsSize : value
      })
      this.fetchMinorResult()
    },
    getMaterialFastenerDistance(width) {
      const materialId = this.sectorFromState.material.id
      return this.materials.find((material) => material.id === materialId)?.rollWidth.find((item) => item.size === width).distance
    },
    setInitialValueDependencyRollSize() {
      if (this.sectors[this.sectorId].isDependOnRollsSize === null) {
        this.UPDATE_SECTOR_DEPENDENCY_ON_ROLLS_SIZE({
          sectorIndex: this.sectorId,
          val: true
        })
      }
    },
    hasSectorMinorResultsAndPart(name) {
      return this.$store.state.minorResults &&
        this.$store.state.minorResults[this.sectorId] &&
        this.$store.state.minorResults[this.sectorId][name]
    }
  },
  mounted() {
    this.setInitialValueDependencyRollSize()
    this.fetchMinorResult()
  },
  computed: {
    ...mapState({
      location: state => state.location,
      defaultSvgParams: state => state.defaultSvgParams,
      insulationMaterials: state => state.insulationMaterials,
      dampCourseMaterials: state => state.materials,
      sectors: state => state.sectors,
      materials: state => state.materials
    }),
    ...mapGetters({
      getIsClearMinorResultNeed: 'isClearMinorResultNeed'
    }),
    isDependOnRollsSize() {
      return this.sectors[this.sectorId].isDependOnRollsSize
    },
    minorResult() {
      return this.hasSectorMinorResultsAndPart('minorResult')
        ? this.$store.state.minorResults[this.sectorId]?.minorResult : []
    },
    fastenersAverage() {
      return this.hasSectorMinorResultsAndPart('fastenersAverage')
        ? this.$store.state.minorResults[this.sectorId]?.fastenersAverage : null
    },
    waterproofingGlueCapacity() {
      return this.hasSectorMinorResultsAndPart('waterproofingGlueCapacity')
        ? this.$store.state.minorResults[this.sectorId]?.waterproofingGlueCapacity : null
    },
    waterproofingGlueCapacityValue() {
      return this.waterproofingGlueCapacity?.value
    },
    hasWaterproofingGlueCapacityError() {
      return Boolean(this.waterproofingGlueCapacity?.error)
    },
    getWidthAlgorithmTitle() {
      return algorithmCalculatingWidthZone[this.isDependOnRollsSize]
    },
    errorMessage: () => errorMessage,
    isClearMinorResultNeed() {
      return this.getIsClearMinorResultNeed(this.sectorId)
    },
    storedMinorResult() {
      return this.sectorFromState.minorResult
    },
    firstZoneOptions() {
      const firstValidZone = this.minorResult.find(zone => zone.hasOwnProperty('options'))
      return firstValidZone.options
    },
    zoneOptionsWithoutRollWidth() {
      const [firstOption] = this.firstZoneOptions
      return getZoneOptionsWithoutRollWidth(firstOption)
    },
    isSomeZoneHasOptions() {
      return this.minorResult?.some(zone => zone.hasOwnProperty('options'))
    },
    currentFigureComponent() {
      return formsComponents[this.buildingData.form]
    },
    sectorId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorId)
    },
    isDisabled() {
      return this.currentMaterial.hasTypes ? this.disableWithTypes : this.disableWithoutTypes
    },
    disableWithTypes() {
      return !(this.sectorFromState.base.id !== null && this.sectorFromState.base.type.size > 0)
    },
    disableWithoutTypes() {
      return this.sectorFromState.base.id === null
    },
    currentMaterial() {
      return this.sectorFromState.base.id === null
        ? this.insulationMaterials[0]
        : this.insulationMaterials.find(p => p.id === this.sectorFromState.base.id)
    },
    buildingData() {
      return this.sectorFromState.building
    },
    getMaterialOverlap() {
      const materialId = this.sectorFromState.material.id
      return this.materials.find((material) => material.id === materialId)?.overlap ?? 0
    },
    zonesNames: () => zonesNames,
    base: () => base,
    dampCourse: () => dampCourse
  }
}
</script>
<style lang="sass">
.summary
  .total-value
    &:first-child
      box-shadow: inset 0 rem(1) 0 $ec
      padding-top: rem(26)
.zones
  display: flex
  flex-direction: row
  align-items: flex-start
  color: $black
  +media((display: (320: flex, 600: grid)))
  grid-template-columns: repeat(3, 1fr)
  +media((grid-column-gap: (600: 0, 992: rem(40))))
  +media((padding-left: (320: rem(13), 800: 0)))
  +media((justify-content: (320: flex-end, 800: space-between)))
  &__wrapper
    display: grid
    +media((padding-top: (320: 0, 576: rem(25), 800: rem(40))))
    +media((grid-template-columns: (320: 4fr 8fr, 450: 5fr 7fr, 480: 1fr 1fr, 800: 1fr)))
  &__titles
    &-mob
      position: relative
      +media((display: (320: flex, 800: none)))
      flex-direction: column
      .tooltip__btn
        position: absolute
        left: 0
        top: 0
        line-height: 0
      &--tooltip
        text-align: left
      &--item
        text-align: left
        font-style: normal
        display: flex
        flex-direction: column
        justify-content: center
        align-items: flex-start
        font-weight: 500
        line-height: rem(18)
        color: $light_black
        position: relative
        height: rem(74)
        +media((font-size: (320: rem(10), 360: rem(12))))
        &:after
          height: rem(1)
          width: calc(100vw - 48px)
          background: $ec
          content: ""
          position: absolute
          bottom: 0
          left: 0
          +media((display: (320: block, 600: none)))
        &:first-of-type
          +media((height: (320: rem(156), 600: rem(116))))
          justify-content: flex-end
          +media((padding-bottom: (320: rem(44), 400: rem(52), 480: rem(62), 600: rem(28), 678: rem(38))))
        &:last-of-type
          padding-bottom: 0
          &:after
            display: none
        br
          +media((display: (320: inline-block, 400: none)))
        span
          +media((display: (320: contents, 400: none)))
  &__item
    +media((border-right: (320: none, 992: rem(1) solid $ec)))
    +media((padding-right: (320: 0, 600: rem(15), 800: rem(25), 992: rem(40))))
    height: 100%
    &:first-of-type
      .zones__item-plusMinus
        .value
          +media((border-radius: (320: rem(4) 0 0 rem(4), 600: rem(4))))
          +media((border-right-width: (320: 0, 600: rem(1))))
    &:nth-child(2)
      .zones__item-plusMinus
        .value
          +media((border-radius: (320: 0, 600: rem(4))))
          +media((border-right-width: (320: 0, 600: rem(1))))
          +media((border-left-width: (320: 0, 600: rem(1))))
    &:last-of-type
      border-color: transparent
      .zones__item-plusMinus
        .value
          +media((border-radius: (320: 0 rem(4) rem(4) 0, 600: rem(4))))
          +media((border-left-width: (320: 0, 600: rem(1))))
    &:first-of-type, &:nth-child(2)
      .zones__item-plusMinus
        .value
          &:after
            content: ""
            background: $ec
            height: rem(24)
            width: rem(1)
            position: absolute
            right: 0
            top: 50%
            transform: translateY(-50%)
            +media((display: (320: block, 800: none)))

    &-title
      font-style: normal
      font-weight: bold
      +media((text-align: (320: center, 800: left)))
      +media((padding-bottom: (320: rem(16), 800: rem(40))))
      +media((font-size: (320: rem(13), 800: rem(18))))
      +media((line-height: (320: rem(16), 800: rem(22))))
      &--type
        +media((display: (320: none, 800: inline-block)))
    &-text
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(18)
      color: $light_black
      text-align: left
      +media((display: (320: none, 800: block)))
    &-params
      display: flex
      flex-direction: row
      align-items: center
      +media((height: (320: rem(74), 800: auto)))
      +media((justify-content: (320: center, 800: flex-start)))
      &:not(:last-child)
        +media((margin-bottom: (800: rem(17))))
      &.estimated
        +media((padding: (800: rem(17) 0 0)))
        +media((box-shadow: (800: inset 0 rem(1) 0 $ec)))

      &.pressure, &.estimated
        .zones__item-params--val
          font-weight: bold
      &.ballast
        +media((padding: (320: 0, 800: 0 0 rem(10))))
      .zones__item-text
        max-width: rem(120)
        width: rem(120)
      &--val
        font-style: normal
        font-weight: 500
        font-size: rem(14)
        line-height: rem(21)
        text-align: left
        .units
          +media((display: (320: none, 800: inline-block)))
.plan
  &__wrapper
    margin-top: rem(34)
    &-title
      font-style: normal
      font-weight: bold
      color: $black
      +media((padding-bottom: (320: rem(20), 768: rem(34))))
      +media((font-size: (320: rem(15), 768: rem(18))))
      +media((line-height: (320: rem(24), 768: rem(22))))
      +media((text-align: (320: center, 768: left)))
    &-algorithm
      .title
        display: flex
        align-items: center
        justify-content: space-between
      .common__title
        @extend %14
        padding: 0
      .switcher
        margin-left: rem(20)
  &__figure
    svg
      max-width: 100%
      height: auto
.minorResult
  &__error
    color: $red
  &__materials
    display: grid
    +media((grid-column-gap: (320: rem(8), 768: rem(40))))
    padding-bottom: rem(34)
    +media((grid-template-columns: (320: 1fr, 576: 1fr 1fr)))
    grid-row-gap: rem(8)
    +media((border-bottom: (320: none, 576: rem(1) solid $ec)))
    &--error
      border-bottom: none
    &-item
      .title
        font-style: normal
        font-weight: bold
        font-size: rem(14)
        line-height: rem(18)
        color: $black
        text-align: left
        padding-bottom: rem(25)
        +media((display: (320: none, 992: block)))
      &--single
        background: $white
        border: rem(1) solid $grey
        box-sizing: border-box
        border-radius: rem(4)
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        transition: .5s
        cursor: pointer
        +media((padding: (320: rem(16) rem(18), 992: rem(14) rem(16) rem(14) rem(20))))
        &:hover
          box-shadow: 0 rem(4) rem(12) rgba(0, 0, 0, 0.15)
          border-color: $ec
          .name
            color: $black
          .btn
            img
              transform: rotate(-180deg)
        .name
          font-style: normal
          font-weight: 500
          line-height: rem(18)
          color: $light_black
          text-align: left
          +media((font-size: (320: rem(12), 360: rem(13), 992: rem(14))))
          br
            +media((display: (320: inline-block, 992: none)))
          &__mobile
            font-size: rem(13)
            line-height: rem(21)
            font-weight: bold
            color: $black
            +media((display: (320: inline-block, 992: none)))
        .btn
          box-shadow: none
          outline: none
          cursor: pointer
          border: none
          background: none
          padding: 0
          line-height: 0
          img
            transition: .5s
.materials
  &__modal
    max-height: 72vh
    width: 100%
    position: fixed
    top: 0
    left: 0
    z-index: 5
    overflow-y: auto
    &-body
      background: $white
      height: auto
      max-height: 100%
      transition: .5s
      padding-bottom: rem(51)
      width: 100%
      box-shadow: 0 rem(4) rem(14) rgba(0, 0, 0, 0.16)
      position: relative
      z-index: 1
    &-mask
      background: rgba(51, 51, 51, 0.3)
      position: fixed
      left: 0
      top: 0
      height: 100vh
      width: 100%
.slide-enter-active, .slide-leave-active
  transition: .5s
.slide-enter, .slide-leave-to
  transform: translateY(-100%)
</style>
