<template>
  <div class="item">
    <input
      type="radio"
      :name="name"
      :value="item.value"
      :id="`radio--${item.value}`"
      v-model="computedType"
    />
    <label :for="`radio--${item.value}`" class="item__name">
      {{ item.name }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
      required: true
    },
    currentType: {
      type: String,
      default: null,
      required: true
    },
    name: {
      type: String,
      default: null,
      required: true
    }
  },
  name: 'CustomRadio',
  computed: {
    computedType: {
      get() {
        return this.currentType
      },
      set(type) {
        this.$emit('updateType', type)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.item
  box-sizing: border-box
  &:not(:last-child)
    margin-bottom: rem(16)
  input
    display: none
    position: absolute
    pointer-events: none
    opacity: 0
    &:checked
      + .item__name
          // font-weight: bold
          &:before
            background: $red
            border-color: $red
          &:after
            opacity: 1
  &__name
    color: $black
    @extend %custom-radio-label
</style>
