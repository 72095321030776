<template>
  <div class="item">
    <input
      type="radio"
      name="fromSelection"
      :id="item.form"
      :value="item.form"
      v-model="computedSelectedForm"
      class="input"
    />
    <label :for="item.form" class="label">
      <img :src="item.src" :alt="item.form" width="100%" class="img img--forward" />
      <img :src="item.srcActive" :alt="item.form" width="100%" class="img img--backward" />
    </label>
  </div>
</template>

<script>
import { shapes } from '@/utils/figures'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
      required: true
    },
    selectedForm: {
      type: String,
      required: true,
      default: shapes.rectangle
    }
  },
  name: 'SelectionItem',
  computed: {
    computedSelectedForm: {
      get() {
        return this.selectedForm
      },
      set(form) {
        this.$emit('updateForm', form)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.item
  @media(any-hover: hover)
    &:hover
      .label
        .img
          &--forward
            left: 0
            top: 0
            position: absolute
            opacity: 0
            pointer-events: none
          &--backward
            position: relative
            opacity: 1
            pointer-events: auto
  .label
    position: relative
    cursor: pointer
    line-height: 0
    display: flex
    transition: .5s
    .img
      transition: .5s
      min-width: rem(120)
      &--backward
        left: 0
        top: 0
        position: absolute
        opacity: 0
        pointer-events: none
  .input
    position: absolute
    display: none
    opacity: 0
    pointer-events: none
    &:checked
      + .label
          box-shadow: inset 0 rem(-4) 0 $red
          .img
            &--forward
              left: 0
              top: 0
              position: absolute
              opacity: 0
              pointer-events: none
            &--backward
              position: relative
              opacity: 1
              pointer-events: auto
</style>
