<template>
  <div class="zones__item-plusMinus">
    <button :disabled="isDecreaseDisabled" class="common__plusMinus-button" @click="decrease">
      <img src="@/assets/img/prev.svg" alt="←" />
    </button>
    <div class="common__plusMinus-input value">
      <slot></slot>
    </div>
    <button :disabled="isIncreaseDisabled" class="common__plusMinus-button" @click="increase">
      <img src="@/assets/img/next.svg" alt="→" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
      default: 0
    },
    availableOptionsLength: {
      type: Number,
      required: true,
      default: 1
    }
  },
  methods: {
    decrease() {
      if (!this.isDecreaseDisabled) {
        this.$emit('updateValue', this.value - 1)
      }
    },
    increase() {
      if (!this.isIncreaseDisabled) {
        this.$emit('updateValue', this.value + 1)
      }
    }
  },
  name: 'PlusMinusInput',
  computed: {
    isDecreaseDisabled() {
      return this.value === 0
    },
    isIncreaseDisabled() {
      return this.value === this.availableOptionsLength - 1
    }
  }
}
</script>

<style scoped lang="sass">
.zones
  &__item
    &-plusMinus
      display: flex
      align-items: center
      +media((padding: (320: 0 0 rem(20), 600: rem(17) 0 rem(27))))
      +media((flex-direction: (320: column, 600: row)))
      .common__plusMinus-button
        +media((transform: (320: rotate(90deg), 600: rotate(0))))
      .value
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        position: relative
        +media((width: (320: rem(60), 360: rem(65), 400: rem(68))))
        +media((font-size: (320: rem(13), 800: rem(14))))
        +media((line-height: (320: rem(19), 800: rem(21))))
        +media((margin: (320: rem(10) 0, 600: 0 rem(5), 800: 0 rem(9))))
        &:hover
          border: rem(1) solid $grey
          box-shadow: none
      .disabled
        opacity: .3
</style>
