<template>
  <section>
    <Nav
      :step="sectorFromState.inAppStep"
      @goToLocation="goToLocation"
      @goToBase="goToBase"
      @goToInsulation="goToInsulation"
      @goToSector="goToSectorFromNav"
      ref="Nav"
    />
    <div class="container">
      <div class="row">
        <div class="minor__title minor__title-btn">
          Гидроизоляция
          <DeleteBtn v-if="sectors.length > 1" @showDeleteConfirmation="showDeleteConfirmation" />
        </div>
        <div class="common__pane">
          <types-selection
            name="insulation-type"
            :items="mounting"
            :disabled-items="disabledMountings"
            :current-type="currentMounting"
            key-to-use="value"
            @updateType="updateSectorMaterialMounting"
          >
            <div class="selection__title">Способ крепления</div>
          </types-selection>
          <div v-if="isCurrentMountingHasTypes" class="mounting__types">
            <div class="title">Тип балласта</div>
            <div class="types">
              <custom-radio
                v-for="item in currentMountingTypes"
                :current-type="material.type"
                :item="item"
                name="ballast-type"
                :key="item.id"
                @updateType="updateSectorMaterialType"
              />
              <custom-radio
                :item="customItem"
                :current-type="material.type"
                name="ballast-type"
                @updateType="updateSectorMaterialType"
              />
              <div class="density" v-if="isCustomMaterialType">
                <div class="density__label">Плотность балласта:</div>
                <input-with-units
                  id="ballast-density"
                  :value="material.density"
                  is-enabled
                  simplified
                  @updateValue="updateSectorMaterialDensity"
                >
                  кг/м³
                </input-with-units>
              </div>
            </div>
          </div>
          <!-- <div class="common__title">Способ крепления</div>
          <div class="styled-radio__wrapper" :class="{ active: material.mounting === 'ballast' }">
            <label
              class="styled-radio styled-radio__wrapper--transform"
              :class="{ active: material.mounting === way.value }"
              :for="`way-${way.value}`"
              v-for="way in mounting"
              :key="`way-${way.value}`"
            >
              <input
                type="radio"
                :value="way.value"
                :id="`way-${way.value}`"
                v-model="material.mounting"
                @change="updateSectorMaterialMounting"
              />
              <span class="checkmark"></span>
              <span class="styled-radio__wrapper--transform-text">{{ way.name }}</span>
            </label>
          </div>
          <div class="dampCourse__subtitle" v-if="getCurrentMounting(material.mounting).hasTypes">
            Тип балласта
          </div>
          <div v-if="getCurrentMounting(material.mounting).hasTypes">
            <label
              class="styled-radio"
              :for="`type-${type.value}`"
              v-for="type in getCurrentMounting(material.mounting).types"
              :class="{ active: material.type === type.value }"
              :key="`type-${type.value}`"
            >
              <input
                type="radio"
                :value="type.value"
                :id="`type-${type.value}`"
                v-model="material.type"
                @change="updateSectorMaterialType"
              />
              {{ type.name }}
              <span class="checkmark"></span>
            </label>
          </div> -->
        </div>
      </div>
      <Materials />
      <div class="row">
        <div class="buttons__nav wizard__buttons-nav">
          <button @click="goToInsulation" class="common__button-prev">
            <img src="@/assets/img/common-arrow-prev.svg" alt="←" />
            <span>Верхний слой теплоизоляции</span>
          </button>
          <button @click="goToSector" class="common__button-next" :disabled="!material.id">
            Параметры участка
            <img src="@/assets/img/common-arrow-next.svg" alt="→" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import Materials from '@/components/smart/Materials.vue'
import Nav from '@/components/smart/Nav.vue'
import DeleteBtn from '@/components/smart/DeleteBtn.vue'
import TypesSelection from '@/components/dump/TypesSelection'
import CustomRadio from '@/components/dump/CustomRadio'
import InputWithUnits from '@/components/dump/inputWithUnits'

const customItem = {
  id: 'irdkf8cd5',
  name: 'Пользовательский материал',
  value: 'custom'
}

export default {
  data: () => ({
    pickedCategory: '',
    showInfo: ''
  }),
  components: {
    InputWithUnits,
    CustomRadio,
    TypesSelection,
    Materials,
    Nav,
    DeleteBtn
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_MATERIAL_PARAM: 'UPDATE_SECTOR_MATERIAL_PARAM',
      UPDATE_SECTOR_MATERIAL_MOUNTING: 'UPDATE_SECTOR_MATERIAL_MOUNTING',
      CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS: 'CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS',
      UPDATE_SECTOR_MATERIAL_DENSITY: 'UPDATE_SECTOR_MATERIAL_DENSITY'
    }),
    updateSectorMaterialDensity(val) {
      this.UPDATE_SECTOR_MATERIAL_DENSITY({
        sectorIndex: this.sectorId,
        val
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateSectorMaterialMounting(mounting) {
      const originalMounting = this.mounting.find(
        originalMounting => originalMounting.value === mounting
      )
      this.UPDATE_SECTOR_MATERIAL_MOUNTING({
        sectorIndex: this.sectorId,
        originalMounting
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateSectorMaterialType(val) {
      this.UPDATE_SECTOR_MATERIAL_PARAM({
        id: this.sectorId,
        param: 'type',
        val,
        isNeedAddDensity: val === 'custom'
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    clearStoredMinorResultRecommendedParams() {
      if (this.isClearMinorResultNeed) {
        this.CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS(this.sectorId)
      }
    },
    showDeleteConfirmation() {
      this.$emit('showDeleteConfirmation')
    },
    goToLocation() {
      this.$root.onBodyScroll()
      this.$emit('goToLocation')
    },
    goToBase() {
      this.$root.onBodyScroll()
      this.$emit('goToBase')
    },
    goToInsulation() {
      this.$root.onBodyScroll()
      this.$emit('goToInsulation')
    },
    goToSector() {
      // dataLayer.push({ event: 'calc-roofload-step5' })
      this.$root.onBodyScroll()
      this.$emit('goToSector')
    },
    goToSectorFromNav() {
      this.$root.onBodyScroll()
      if (this.material.id) {
        this.$emit('goToSector')
      }
    },
    getCurrentMounting(mounting) {
      return this.mounting[this.mounting.findIndex(p => p.value === mounting)]
    }
  },
  computed: {
    ...mapState({
      sectors: state => state.sectors,
      mounting: state => state.mounting
    }),
    ...mapGetters({
      getIsClearMinorResultNeed: 'isClearMinorResultNeed',
      freeFixationInsulationMethodId: 'freeFixationInsulationMethodId'
    }),
    disabledMountings() {
      const { sectorFromState, freeFixationInsulationMethodId } = this
      const isInsulationEnabled = sectorFromState.insulation.isEnabled
      if (isInsulationEnabled) {
        const currentInsulationMethod = sectorFromState.insulation.data.method
        return currentInsulationMethod === freeFixationInsulationMethodId
          ? ['gluing', 'mechanical']
          : []
      } else {
        return []
      }
    },
    isClearMinorResultNeed() {
      return this.getIsClearMinorResultNeed(this.sectorId)
    },
    sectorId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorId)
    },
    material() {
      return this.sectorFromState.material
    },
    currentMounting() {
      return this.material.mounting
    },
    isCurrentMountingHasTypes() {
      return this.mounting.find(mounting => mounting.value === this.currentMounting).hasTypes
    },
    currentMountingTypes() {
      return this.mounting.find(mounting => mounting.value === this.currentMounting).types
    },
    isCustomMaterialType() {
      return this.material.type === 'custom'
    },
    customItem: () => customItem
  }
}
</script>
<style lang="sass" scoped>
.common__pane
  .selection
    // +media((margin-bottom: (320: rem(32), 768: rem(42))))
    &__title
      font-style: normal
      font-weight: bold
      +media((font-size: (320: rem(15), 768: rem(18))))
      color: $black
      line-height: rem(22)
      text-align: left
      margin-bottom: rem(24)
.mounting
  &__types
    +media((box-shadow: (320: 0 rem(-1) 0 $ec, 768: none)))
    +media((padding-top: (320: rem(32), 768: 0)))
    +media((margin-top: (768: rem(42))))
    .title
      text-align: left
      display: block
      margin-bottom: rem(24)
      @extend %14
      font-weight: bold
      color: $black
    .types
      .density
        display: flex
        align-items: center
        margin-left: rem(40)
        &__label
          text-align: left
          font-style: normal
          font-weight: 500
          font-size: rem(12)
          line-height: 100%
          color: $black
          margin-right: rem(10)
        ::v-deep
          .input-wrapper
            flex: 0 0 rem(120)
            max-width: rem(120)
            .input
              max-width: rem(60)
// .styled
  &-radio
    &__wrapper
      flex-wrap: wrap
      &.active
        +media((padding-bottom: (320: rem(17), 992: rem(42))))
        +media((border-bottom: (320: rem(1) solid #ECECEC, 992: none)))
// .dampCourse
  &__subtitle
    font-style: normal
    font-weight: bold
    text-align: left
    color: $black
    padding-bottom: rem(24)
    +media((font-size: (320: rem(13), 992: rem(14))))
    +media((line-height: (320: rem(21), 992: rem(18))))
    +media((padding-top: (320: rem(32), 992: 0)))
  &__title
    padding: rem(82) 0 rem(21)
  &__filter
    display: flex
    flex-direction: row
    align-items: center
    padding-bottom: rem(34)
    &-item
      font-style: normal
      font-weight: bold
      font-size: rem(14)
      line-height: rem(17)
      color: #5C5C5C
      margin-right: rem(30)
      padding: rem(21) 0 rem(25)
      transition: .5s
      cursor: pointer
      position: relative
      &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        height: rem(4)
        width: 0
        background: $red
        transition: .5s
      &:hover
        color: $black
        &:after
          width: 50%
      &.active
        color: $black
        &:after
          width: 100%
      input[type="radio"]
        position: absolute
        left: 0
        top: 0
        height: 0
        width: 0
        display: none
        opacity: 0
        pointer-events: none
  // &__materials
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-column-gap: rem(1)
    grid-row-gap: rem(1)
    &-item
      background: #ffffff
      padding: rem(17) rem(20) rem(22)
      display: flex
      flex-direction: column
      cursor: pointer
      position: relative
      &:hover, &.active
        .dampCourse__materials-item--img
          &:after
            opacity: 1
        .dampCourse__materials-item--name
          color: $red
      &--img
        line-height: 0
        position: relative
        &:after
          content: url(~@/assets/img/tick.svg)
          position: absolute
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
          line-height: 0
          opacity: 0
          pointer-events: none
          transition: .5s
          display: flex
          flex-direction: row
          justify-content: center
          align-items: center
        img
          width: 100%
      &--name
        font-style: normal
        font-weight: 600
        font-size: rem(14)
        line-height: rem(21)
        color: $black
        text-align: left
        width: 100%
        padding-top: rem(21)
        display: flex
        flex-direction: row
        align-items: center
        .showInfo
          &-btn
            line-height: 0
            cursor: help
            padding-left: rem(20)
      .info
        position: absolute
        background: #FFFFFF
        box-shadow: 0 rem(4) rem(14) rgba(0, 0, 0, 0.16)
        border-radius: rem(4)
        width: 100%
        left: 0
        top: 0
        min-height: 100%
        padding: rem(20) rem(20) rem(14)
        display: flex
        flex-direction: column
        align-items: flex-start
        z-index: 2
        &__title
          font-style: normal
          font-weight: 600
          font-size: rem(14)
          line-height: rem(21)
          text-align: left
          padding-right: rem(40)
        &__text
          text-align: left
          font-style: normal
          font-weight: 500
          font-size: rem(12)
          line-height: rem(20)
          color: #5C5C5C
          padding-top: rem(14)
          &-roll
            b
              padding-right: rem(16)
              position: relative
              &.divider
                &:after
                  content: "|"
                  color: #D6D6D6
                  position: absolute
                  right: rem(8)
        .close
          background: none
          box-shadow: none
          border: none
          padding: rem(18)
          position: absolute
          right: 0
          top: 0
          line-height: 0
          cursor: pointer
          outline: none
    input[type="radio"]
        position: absolute
        left: 0
        top: 0
        height: 0
        width: 0
        display: none
        opacity: 0
        pointer-events: none
</style>
