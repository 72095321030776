<template>
  <div class="switcher">
    <input type="checkbox" v-model="computedIsToggled" :id="id" />
    <label :for="id" class="switcher__label"></label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
      default: ''
    },
    isToggled: {
      type: Boolean,
      required: true
    }
  },
  name: 'switcher',
  computed: {
    computedIsToggled: {
      get() {
        return this.isToggled
      },
      set(val) {
        this.$emit('updateStatus', val)
      }
    }
  }
}
</script>

<style scoped lang="sass">
$track-width: rem(42)
$toggler-width: rem(24)
.switcher
  min-height: rem(24)
  display: flex
  align-items: center
  input
    display: none
    pointer-events: none
    opacity: 0
    position: absolute
    &:checked
      + .switcher__label
        &:after
          transform: translate(calc(#{$track-width} - #{$toggler-width}), -50%)
          left: unset
          background: $red
          border-color: $red
  &__label
    height: rem(14)
    width: $track-width
    background: $ec
    border-radius: rem(12)
    position: relative
    display: block
    cursor: pointer
    box-shadow: inset 0 rem(2) rem(4) $tn_gray
    &:after
      content: ""
      background: $white
      height: rem(24)
      width: $toggler-width
      border: rem(1) solid $tn_gray
      display: block
      border-radius: 50%
      position: absolute
      top: 50%
      left: 0
      transition: .5s
      transform: translate(0, -50%)
      box-shadow: 0 rem(3) rem(6) rgba(0, 0, 0, 0.16)
</style>
