const units = {
  mm: 'мм',
  'pc/m2': 'шт / м²',
  kPa: 'кПа',
  N: 'Н'
}

export const optionsText = {
  step: {
    firstWord: 'Шаг',
    secondWord: 'крепежа',
    title: function () {
      return `${this.firstWord} ${this.secondWord}`
    },
    units: units.mm,
    titleMobile: function () {
      return `${this.firstWord}<br />${this.secondWord},<br />${units.mm}`
    }
  },
  required: {
    firstWord: 'Требуемое',
    secondWord: 'к-во крепежа',
    title: function () {
      return `${this.firstWord} ${this.secondWord}`
    },
    units: units['pc/m2'],
    titleMobile: function () {
      return `${this.firstWord}<br />${this.secondWord},<br />${units['pc/m2']}`
    }
  },
  estimated: {
    firstWord: 'Расчетное',
    secondWord: 'к-во крепежа',
    title: function () {
      return `${this.firstWord} ${this.secondWord}`
    },
    units: units['pc/m2'],
    titleMobile: function () {
      return `<b>${this.firstWord}<br />${this.secondWord},<br />${units['pc/m2']}</b>`
    }
  },
  thickness: {
    firstWord: 'Толщина',
    secondWord: 'балласта',
    title: function () {
      return `${this.firstWord}<br> ${this.secondWord}`
    },
    units: units.mm,
    titleMobile: function () {
      return `${this.firstWord}<br /> ${this.secondWord}, ${units.mm}`
    }
  },
  pressure: {
    firstWord: 'Ветровое',
    secondWord: 'давление',
    title: function () {
      return `${this.firstWord} ${this.secondWord}`
    },
    units: units.kPa,
    titleMobile: function () {
      return `<b>${this.firstWord} <br> ${this.secondWord}, ${units.mm}</b>`
    }
  },
  fasteners_rowlength: {
    firstWord: 'Расстояние',
    secondWord: 'м/у рядами',
    title: function () {
      return `${this.firstWord} ${this.secondWord}`
    },
    units: units.mm,
    titleMobile: function () {
      return `${this.firstWord} <br>${this.secondWord}, ${units.mm}`
    }
  },
  resistance: {
    firstWord: 'Несущая',
    secondWord: 'способность',
    title: function () {
      return `${this.firstWord} ${this.secondWord}`
    },
    units: units.N,
    titleMobile: function () {
      return `${this.firstWord} <br>${this.secondWord}, ${units.N}`
    }
  },
  ballast_type: {
    firstWord: 'Тип',
    secondWord: 'балласта',
    title: function () {
      return `${this.firstWord} ${this.secondWord}`
    },
    units: '',
    titleMobile: function () {
      return `${this.firstWord} <br>${this.secondWord}`
    }
  }
}
