<template>
  <label :for="id" :class="wrapperClasses">
    <input type="number" class="input" :id="id" v-model.number="computedValue" v-if="isEnabled" />
    <span class="plug" v-else>{{ value }}</span>
    <span class="units"><slot></slot></span>
  </label>
</template>

<script>
import { isValidNumber } from '@/utils'

export default {
  props: {
    simplified: {
      type: Boolean
    },
    isEnabled: {
      type: Boolean
    },
    value: {
      type: Number,
      required: true,
      default: 0
    },
    id: {
      type: String,
      required: true,
      default: ''
    }
  },
  name: 'inputWithUnits',
  computed: {
    wrapperClasses() {
      return {
        'input-wrapper': true,
        'input-wrapper--disabled': !this.isEnabled,
        'input-wrapper--simplified': this.simplified
      }
    },
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        if (isValidNumber(val)) {
          this.$emit('updateValue', val)
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.input-wrapper
  display: flex
  align-items: center

  background: $white
  border: rem(1) solid $tn_gray
  box-sizing: border-box
  border-radius: rem(4)
  +media((flex: (320: 0 0 rem(80), 768: 0 0 rem(96))))
  +media((max-width: (320: rem(80), 768: rem(96))))
  +media((min-height: (320: rem(34), 768: rem(40))))
  +media((padding: (320: 0 rem(10), 768: 0 rem(12))))
  transition: .5s
  .input, .units, .plug
    font-family: $main-font
    font-style: normal
    display: flex
    align-items: center
    text-align: center
    color: $black
  .input, .plug
    outline: none
    border: none
    background: none
    text-align: left
    +media((font-size: (320: rem(16), 768: rem(18))))
    +media((line-height: (320: rem(18), 768: rem(22))))
    font-weight: bold
    text-overflow: ellipsis
    +media((max-width: (320: rem(40), 768: rem(60))))
    +media((padding: (320: 0 rem(10) 0 0, 768: 0 rem(12) 0 0)))
  .input
    +media((min-height: (320: rem(32), 768: rem(38))))
  .units
    text-align: left
    margin-left: auto
    font-weight: 600
    font-size: rem(14)
    line-height: rem(17)
  .plug
    display: block
    min-height: unset
    overflow: hidden
  &--disabled
    cursor: no-drop
    background: $ec
    .input, .units, .plug
      color: rgba($tn_gray, .9)
  &--simplified
    .input
      font-size: rem(16)
      line-height: 100%
      font-weight: 600
    .units
      font-size: rem(12)
      line-height: 100%
      font-weight: 500
</style>
