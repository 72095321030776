<template>
  <div class="sector-name">
    <label for="sector-name" class="sector-name__control">
      <span class="icon">
        <img src="@/assets/img/pen--red.svg" alt="pen" />
      </span>
      <input type="text" class="input" id="sector-name" v-model="computedSectorName" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'SectorNameChanger',
  props: {
    name: {
      type: String,
      default: null,
      required: true
    }
  },
  computed: {
    computedSectorName: {
      get() {
        return this.name
      },
      set(val) {
        this.$emit('updateName', val)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.sector-name
  background: $white
  +media((margin: (0: 0 auto rem(34), 992: 0 0 rem(34))))
  +media((width: (0: calc(100% - 48px), 992: 100%)))
  // margin: 0 rem(-15)
  // +media((padding: (320: rem(34) rem(25), 992: rem(34) rem(40))))
  &__control
    border: rem(1) solid $another-white
    box-sizing: border-box
    border-radius: rem(4)
    padding: rem(10)
    display: grid
    grid-template-columns: rem(32) 1fr
    gap: rem(12)
    align-items: center
    .icon
      line-height: 0
      height: rem(32)
      width: rem(32)
      display: flex
      align-items: center
      justify-content: center
    .input
      width: 100%
      font-family: $main-font
      font-style: normal
      font-weight: bold
      font-size: rem(14)
      line-height: 17px
      height: 100%
      border: none
      padding: 0
      outline: none
      text-overflow: ellipsis
</style>
