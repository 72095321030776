<template>
  <div class="zones__titles-mob--item" v-html="title"></div>
</template>

<script>
import { optionsText } from '@/utils/MinorResultTitles'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  name: 'MobileTitle',
  computed: {
    title() {
      return optionsText[this.item.key].titleMobile()
    }
  }
}
</script>

<style scoped></style>
