<template>
  <div class="zones__item">
    <div class="zones__item-title">
      <slot />
    </div>
    <template v-if="zone.options">
      <div class="zones__item-text">Ширина рулона гидроизоляции</div>
      <plus-minus-input
        :value="storedRecommended || 0"
        :available-options-length="zone.options.length"
        @updateValue="updateValue"
      >
        {{ recommendedOptions ? recommendedOptions.rollWidth : '' }} м
      </plus-minus-input>
      <template v-if="recommendedOptions">
        <common-item
          v-for="[key, value] in zoneOptionsWithoutRollWidth"
          :item="{ key, value }"
          :key="key"
        />
      </template>
      <div class="title-switcher" v-if="zone.isDoubleFastener">
        <div class="title-switcher__text">
          Двойной <br />
          крепеж
        </div>
        <switcher
          :is-toggled="zone.doubleFastenerBySide[zone.name]"
          :id="zone.name"
          @updateStatus="updateStatus"
        />
      </div>
    </template>
    <zone-error :error-message="errorMessage" v-else />
  </div>
</template>

<script>
import { getZoneOptionsWithoutRollWidth } from '@/utils'
import PlusMinusInput from '@/components/dump/MinorResultTable/PlusMinusInput'
import CommonItem from '@/components/dump/MinorResultTable/CommonItem'
import ZoneError from '@/components/dump/MinorResultTable/ZoneError'
import Switcher from '@/components/dump/switcher'

export default {
  props: {
    zone: {
      type: Object,
      default: () => ({})
    },
    errorMessage: {
      type: String
    },
    storedRecommended: null
  },
  name: 'Zone',
  components: { ZoneError, CommonItem, PlusMinusInput, Switcher },
  methods: {
    updateValue(val) {
      this.$emit('updateValue', val)
    },
    updateStatus(val) {
      this.$emit('updateStatus', val)
    }
  },
  computed: {
    recommendedOptions() {
      return this.storedRecommended === null
        ? this.zone?.options[0]
        : this.zone?.options[this.storedRecommended]
    },
    zoneOptionsWithoutRollWidth() {
      return getZoneOptionsWithoutRollWidth(this.recommendedOptions)
    }
  }
}
</script>

<style scoped lang="sass">
.title-switcher
  display: flex
  align-items: center
  justify-content: center
  &__text
    @extend %14
    color: $black
    margin-right: rem(10)
</style>
