<template>
  <div class="zone-error">
    <div class="zone-error__text">
      {{ errorMessage }}
    </div>
    <tippy :max-width="240" class="zone-error__tooltip">
      <template #trigger>
        <img src="@/assets/img/info.svg" alt="i" />
      </template>
      {{ errorMessage }}
    </tippy>
  </div>
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String
    }
  },
  name: 'ZoneError'
}
</script>

<style scoped lang="sass">
.zone-error
  font-size: rem(12)
  line-height: rem(18)
  text-align: left
  color: $red

  &__tooltip
    +media((display: (800: none)))
    +media((min-height: (0: rem(104), 600: rem(74))))
    display: flex
    justify-content: center
    align-items: center
    &::v-deep
      > div
        line-height: 0
  &__text
    +media((display: (0: none, 800: block)))
</style>
