<template>
  <div class="input-box">
    <label class="input-box__label" :for="`input--${computedParam.key}`"> {{ label }}, м </label>
    <span class="input-box__shortcut" :style="{ background: currentShortcutColor }">
      {{ shortcut }}
    </span>
    <div class="input-box__content">
      <input
        type="number"
        v-model.number="computedVal"
        class="input"
        min="0"
        max="1000"
        :id="`input--${computedParam.key}`"
      />
    </div>
  </div>
</template>

<script>
import { figureColors, isValidNumber } from '@/utils'
import { figures, figuresLabels, figuresSetZero } from '@/utils/figures'

export default {
  props: {
    param: {
      type: Array,
      default: () => [],
      required: true
    },
    form: {
      type: String,
      default: null,
      required: true
    }
  },
  name: 'paramInput',
  computed: {
    sectorId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorId)
    },
    currentShortcutColor() {
      return figureColors[this.computedParam.key]
    },
    label() {
      return figuresLabels[this.form][this.computedParam.key]
    },
    setZero() {
      let { allow, bounded } = figuresSetZero[this.form][this.computedParam.key]
      return { allow, bounded }
    },
    shortcut() {
      return this.computedParam.key
    },
    computedParam() {
      const [key, value] = this.param
      return { key, value }
    },
    boundedParent() {
      const parent = Object.entries(figuresSetZero[this.form]).find(([key, value]) => value.bounded.includes(this.shortcut))

      return parent ? parent[0] : null
    },
    defaultValues() {
      return figures.find((figure) => figure.form === this.form).params
    },
    computedVal: {
      get() {
        return this.computedParam.value
      },
      set(value) {
        if (this.computedParam.key !== 'h') {
          const min = this.setZero.allow ? 0 : 0.09
          if (isValidNumber(value, min)) {
            this.$emit('updateParam', { param: this.computedParam.key, value })

            if (value === 0 && this.setZero.allow && this.setZero.bounded.length > 0) {
              this.setZero.bounded.forEach(param => {
                this.$emit('updateParam', { param: param, value })
              })
            }
            if (this.boundedParent && this.getCurrentParentValue(this.boundedParent) === 0) {
              this.$emit('updateParam', {
                param: this.boundedParent,
                value: this.defaultValues[this.boundedParent]
              })
            }
          }
        } else {
          const min = 1
          isValidNumber(value, min)
            ? this.$emit('updateParam', { param: this.computedParam.key, value })
            : this.$emit('updateParam', { param: this.computedParam.key, min })
        }
      }
    }
  },
  methods: {
    getCurrentParentValue(key) {
      return this.sectorFromState.building.params[key]
    }
  }
}
</script>

<style scoped lang="sass">
.input-box
  width: 100%
  +media((max-width: (768: rem(240))))
  // +media((min-height: (320: rem(40), 768: rem(50))))
  display: grid
  grid-row-gap: rem(11)
  grid-template-columns: rem(20) 1fr
  &__label
    font-style: normal
    font-weight: 500
    font-size: rem(12)
    line-height: 100%
    height: 100%
    color: $light_black
    width: 100%
    grid-column: span 2
    // +media((max-width: (320: rem(60), 768: rem(112))))
    text-align: left
  &__shortcut
    background: $another-white
    // +media((width: (320: rem(16), 768: rem(20))))
    width: rem(20)
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    font-style: normal
    font-weight: bold
    font-size: rem(12)
    line-height: 100%
    color: $white
    // +media((flex: (320: 0 0 rem(16), 768: 0 0 rem(20))))
    flex: 0 0 rem(20)
    border-radius: rem(4) 0 0 rem(4)
    min-height: rem(40)
  &__content
    display: flex
    align-items: center
    .input
      //+media((width: (320: rem(40), 768: rem(68))))
      width: 100%
      min-height: inherit
      font-family: $main-font
      padding: 0 rem(20)
      text-overflow: ellipsis
      margin: 0 0 0 auto
      font-style: normal
      font-weight: 600
      +media((font-size: (320: rem(16), 768: rem(18))))
      +media((line-height: (320: 100%, 768: rem(22))))
      color: rgba(#1C1C1B, .9)
      text-align: left
      outline: none
      border: rem(1) solid $another-white
      border-radius: 0 rem(4) rem(4) 0
      border-left: none
      height: 100%
</style>
