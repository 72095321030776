<template>
  <div class="title-switcher">
    <div class="title-switcher__text">Использовать двойной крепеж</div>
    <div class="title-switcher__tooltip">
      <tippy :max-width="240">
        <template #trigger>
          <img src="@/assets/img/question.svg" alt="?" />
        </template>
        {{ tooltipText }}
      </tippy>
    </div>
    <switcher :is-toggled="isChecked" id="doubleFastener" @updateStatus="updateStatus" />
  </div>
</template>

<script>
import Switcher from '@/components/dump/switcher'
const tooltipText =
  'Установка двух крепежей в одну полку профлиста, рассматривается как одна точка фиксации'

export default {
  components: { Switcher },
  props: {
    isChecked: {
      type: Boolean,
      required: true
    }
  },
  name: 'DoubleFastenerSwitcher',
  methods: {
    updateStatus(val) {
      this.$emit('updateStatus', val)
    }
  },
  computed: {
    tooltipText: () => tooltipText
  }
}
</script>

<style scoped lang="sass">
.title-switcher
  display: flex
  align-items: center
  &__text
    @extend %14
    color: $black
    margin-right: rem(10)
    text-align: left
  &__tooltip
    cursor: help
    line-height: 0
    margin-right: rem(18)
</style>
