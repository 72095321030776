<template>
  <section>
    <Nav
      :step="sectorFromState.inAppStep"
      @goToLocation="goToLocation"
      @goToBase="goToBase"
      @goToDampCourse="goToMaterial"
      ref="Nav"
    />
    <div class="container">
      <div class="row">
        <div class="minor__title minor__title-btn">
          Верхний слой теплоизоляции
          <DeleteBtn v-if="sectors.length > 1" @showDeleteConfirmation="showDeleteConfirmation" />
        </div>
        <div
          class="common__pane common__pane--insulation"
          :class="{ 'common__pane--disabled': !insulation.isEnabled }"
        >
          <div class="title">
            <div class="common__title">
              {{ title }}
            </div>
            <switcher
              :is-toggled="insulation.isEnabled"
              id="insulationExisting"
              @updateStatus="updateInsulationNecessity"
            />
          </div>
          <types-selection
            v-if="insulation.isEnabled"
            name="insulation-type"
            :items="insulationTypes"
            :current-type="insulation.data.type"
            @updateType="updateInsulationType"
          >
            <div class="selection__title">Тип утеплителя</div>
          </types-selection>
          <types-selection
            v-if="insulation.isEnabled"
            name="fixing-method"
            :items="insulationMethods"
            :current-type="insulation.data.method"
            @updateType="updateInsulationMethod"
          >
            <div class="selection__title">Способ фиксации утеплителя</div>
          </types-selection>
          <!-- <div class="insulation__subtitle" v-if="insulation.isEnabled">
            Способ фиксации утеплителя
          </div>
          <div class="styled-radio__wrapper" v-if="insulation.isEnabled">
            <label
              class="styled-radio styled-radio__wrapper--transform"
              :class="{ active: insulation.data.method === item.value }"
              :for="`type-${item.value}`"
              v-for="(item, i) in methods"
              :key="i"
            >
              <input
                type="radio"
                v-model="insulation.data.method"
                :value="item.value"
                :id="`type-${item.value}`"
                @change="updateInsulationType"
              />
              <span class="checkmark"></span>
              <span class="styled-radio__wrapper--transform-text">{{ item.name }}</span>
            </label>
          </div>
          <div v-if="insulation.isEnabled" class="insulation__thickness">
            <div class="insulation__subtitle insulation__thickness-title">
              Толщина утеплителя, мм
            </div>
            <div class="insulation__thickness-input">
              <button
                @click="
                  insulation.data.thickness -= 10
                  updateInsulationThickness()
                "
                :disabled="insulation.data.thickness < 10"
                class="common__plusMinus-button"
              >
                <img src="@/assets/img/minus.svg" alt="-" />
              </button>
              <input
                type="number"
                class="common__plusMinus-input"
                min="0"
                step="10"
                v-model.number="insulation.data.thickness"
                @change="updateInsulationThickness"
              />
              <button
                @click="
                  insulation.data.thickness += 10
                  updateInsulationThickness()
                "
                class="common__plusMinus-button"
              >
                <img src="@/assets/img/plus.svg" alt="+" />
              </button>
            </div>
          </div> -->
        </div>
        <div class="buttons__nav wizard__buttons-nav">
          <button @click="goToBase" class="common__button-prev">
            <img src="@/assets/img/common-arrow-prev.svg" alt="←" />
            <span>Основание кровли</span>
          </button>
          <button
            @click="goToMaterial"
            class="common__button-next"
            :disabled="
              (insulation.isEnabled && insulation.data.thickness < 0) ||
              (insulation.isEnabled && insulation.data.thickness === '')
            "
          >
            Гидроизоляция
            <img src="@/assets/img/common-arrow-next.svg" alt="→" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import Nav from '@/components/smart/Nav.vue'
import DeleteBtn from '@/components/smart/DeleteBtn.vue'
import Switcher from '@/components/dump/switcher'
import TypesSelection from '@/components/dump/TypesSelection'

export default {
  components: {
    TypesSelection,
    Switcher,
    Nav,
    DeleteBtn
  },
  methods: {
    ...mapMutations({
      UPDATE_INSULATION_NECESSITY: 'UPDATE_INSULATION_NECESSITY',
      UPDATE_INSULATION_PARAM: 'UPDATE_INSULATION_PARAM',
      CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS: 'CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS'
    }),
    ...mapActions({
      updateSectorInsulationMethod: 'updateSectorInsulationMethod'
    }),
    updateInsulationNecessity(val) {
      this.UPDATE_INSULATION_NECESSITY({ id: this.sectorId, val })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateInsulationType(val) {
      this.UPDATE_INSULATION_PARAM({
        id: this.sectorId,
        param: 'type',
        val
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateInsulationMethod(val) {
      this.updateSectorInsulationMethod({
        sectorIndex: this.sectorId,
        val,
        isNeedToUpdateMounting:
          val === this.freeFixationInsulationMethodId && !this.sectorMountingIsBallast
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    clearStoredMinorResultRecommendedParams() {
      if (this.isClearMinorResultNeed) {
        this.CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS(this.sectorId)
      }
    },
    // updateInsulationThickness() {
    //   this.UPDATE_INSULATION_PARAM({
    //     id: this.sectorId,
    //     param: 'thickness',
    //     val: this.insulation.data.thickness
    //   })
    //   if (this.isClearNeed) {
    //     this.CLEAR_SECTOR_MATERIAL_SIZE_PARAMS(this.sectorId)
    //   }
    // },
    showDeleteConfirmation() {
      this.$emit('showDeleteConfirmation')
    },
    goToLocation() {
      this.$emit('goToLocation')
    },
    goToBase() {
      this.$emit('goToBase')
    },
    goToMaterial() {
      // dataLayer.push({ event: 'calc-roofload-step4' })
      this.$emit('goToDampCourse')
    }
  },
  computed: {
    ...mapState({
      sectors: state => state.sectors,
      insulationTypes: state => state.insulationTypes,
      insulationMethods: state => state.insulationMethods
    }),
    ...mapGetters({
      getIsClearMinorResultNeed: 'isClearMinorResultNeed',
      freeFixationInsulationMethodId: 'freeFixationInsulationMethodId'
    }),
    sectorMountingIsBallast() {
      return this.sectorFromState.material.mounting === 'ballast'
    },
    isClearMinorResultNeed() {
      return this.getIsClearMinorResultNeed(this.sectorId)
    },
    sectorId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorId)
    },
    insulation() {
      return this.sectorFromState.insulation
    },
    title() {
      const defaultTitle = 'Наличие утеплителя'
      const suffix = ': нет'
      return this.insulation.isEnabled ? defaultTitle : `${defaultTitle}${suffix}`
    }
  }
}
</script>
<style lang="sass" scoped>
.common__pane
  &--insulation
    +media((padding: (320: rem(32) rem(24), 992: rem(34) rem(40) rem(51))))
    .title
      display: flex
      align-items: center
      .common__title
        padding: 0
      .switcher
        margin-left: rem(20)
  .selection
    +media((margin-top: (320: rem(32), 768: rem(51))))
    &__title
      @extend %14
      font-weight: bold
      text-align: left
      color: $black
      margin-bottom: rem(18)
  &--disabled
    +media((padding: (320: rem(32) rem(24), 992: rem(34) rem(40))))
// .insulation
  &__subtitle
    color: $black
    text-align: left
    font-style: normal
    font-weight: bold
    +media((padding: (320: rem(8) 0 rem(25), 992: rem(47) 0 rem(25))))
    +media((font-size: (320: rem(13), 992: rem(14))))
    +media((line-height: (320: rem(21), 992: rem(18))))
  &__thickness
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    box-shadow: inset 0 rem(1) 0 #ECECEC
    +media((padding-top: (320: rem(24), 992: rem(34))))
    &-title
      padding: 0
      +media((padding-right: (320: rem(20), 480: rem(40))))
    &-input
      display: flex
      flex-direction: row
      align-items: center
// .styled
  &-radio
    &__wrapper
      +media((padding-bottom: (320: rem(17), 768: rem(51))))
      +media((flex-direction: (320: column, 768: row)))
      display: flex
</style>
