<template>
  <section>
    <Nav
      :step="sectorFromState.inAppStep"
      @goToLocation="goToLocation"
      @goToBase="goToBase"
      @goToInsulation="goToInsulation"
      @goToDampCourse="goToDampCourse"
      @goToMinorResult="goToMinorResult"
      @addFromNav="addFromNav"
      ref="Nav"
    />
    <div class="container">
      <div class="row">
        <div class="minor__title minor__title-btn">
          Параметры участка кровли
          <DeleteBtn v-if="sectors.length > 1" @showDeleteConfirmation="showDeleteConfirmation" />
        </div>
        <div class="sector-options">
          <sector-name-changer :name="sectorFromState.sectorName" @updateName="updateSectorName" />
          <building-form-selection
            :selected-form="buildingData.form"
            @updateForm="updateSectorBuildingForm"
          />
          <div class="sector-options__building">
            <div class="figure">
              <svg-wrapper is-need-code @updateFigureCode="updateSectorImage" ref="asd">
                <component
                  ref="building"
                  :is="currentFigureComponent"
                  :current="buildingData"
                  :buildingHeight="buildingData.height"
                  :isSectorPage="true"
                ></component>
              </svg-wrapper>
            </div>
            <div class="inputs">
              <div class="inputs__title">Размеры участка</div>
              <param-input
                form="any"
                :param="['h', buildingData.height]"
                @updateParam="updateSectorBuildingHeight"
              />
              <param-input
                v-for="(param, i) in Object.entries(buildingData.params)"
                :form="buildingData.form"
                :param="param"
                :key="`param--${i}`"
                @updateParam="updateSectorBuildingParam"
              />
            </div>
          </div>
        </div>
        <div class="buttons__nav wizard__buttons-nav">
          <button @click="goToDampCourse" class="common__button-prev">
            <img src="@/assets/img/common-arrow-prev.svg" alt="←" />
            <span>Гидроизоляция</span>
          </button>
          <button @click="goToMinorResult" class="common__button-next special">
            Промежуточный расчет
            <img src="@/assets/img/common-arrow-next.svg" alt="→" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { formsComponents } from '@/utils'
import { figures } from '@/utils/figures'
import { mapState, mapMutations, mapGetters } from 'vuex'
import Figure from '@/Archive/Figure.vue'
import Nav from '@/components/smart/Nav.vue'
import DeleteBtn from '@/components/smart/DeleteBtn.vue'
import SectorNameChanger from '@/components/dump/SectorNameChanger'
import BuildingFormSelection from '@/components/dump/Sector/BuildingFormSelection'
import ParamInput from '@/components/dump/Figure-controls/paramInput'
import SvgWrapper from '@/components/dump/Figures/SvgWrapper'

export default {
  components: {
    SvgWrapper,
    ParamInput,
    BuildingFormSelection,
    SectorNameChanger,
    Figure,
    Nav,
    DeleteBtn
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_PARAM: 'UPDATE_SECTOR_PARAM',
      UPDATE_SECTOR_BUILDING_FORM: 'UPDATE_SECTOR_BUILDING_FORM',
      UPDATE_SECTOR_BUILDING_PARAM: 'UPDATE_SECTOR_BUILDING_PARAM',
      UPDATE_SECTOR_BUILDING_HEIGHT: 'UPDATE_SECTOR_BUILDING_HEIGHT',
      CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS: 'CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS'
    }),
    updateSectorBuildingForm(form) {
      const newBuildingParams = figures.find(figure => figure.form === form).params
      this.UPDATE_SECTOR_BUILDING_FORM({
        sectorIndex: this.sectorId,
        form,
        params: newBuildingParams
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateSectorBuildingParam(kit) {
      const { param, value } = kit
      this.UPDATE_SECTOR_BUILDING_PARAM({
        sectorIndex: this.sectorId,
        param,
        value: Number(value.toFixed(1))
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateSectorBuildingHeight(kit) {
      const { value } = kit
      this.UPDATE_SECTOR_BUILDING_HEIGHT({
        sectorIndex: this.sectorId,
        value: value ? Number(value.toFixed(1)) : 1
      })
      this.clearStoredMinorResultRecommendedParams()
    },
    updateSectorName(val) {
      this.UPDATE_SECTOR_PARAM({
        id: this.sectorId,
        param: 'sectorName',
        val
      })
    },
    updateSectorImage(val) {
      this.UPDATE_SECTOR_PARAM({
        id: this.sectorId,
        param: 'sectorImage',
        val
      })
    },
    clearStoredMinorResultRecommendedParams() {
      if (this.isClearMinorResultNeed) {
        this.CLEAR_SECTOR_MINOR_RESULT_RECOMMENDED_PARAMS(this.sectorId)
      }
    },
    showDeleteConfirmation() {
      this.$emit('showDeleteConfirmation')
    },
    goToLocation() {
      this.$emit('goToLocation')
    },
    goToBase() {
      this.$emit('goToBase')
    },
    goToInsulation() {
      this.$emit('goToInsulation')
    },
    goToDampCourse() {
      this.$emit('goToDampCourse')
    },
    goToMinorResult() {
      // dataLayer.push({ event: 'calc-roofload-step6' })
      this.$emit('goToMinorResult')
    },
    addFromNav() {
      this.$emit('addFromNav')
    }
  },
  computed: {
    ...mapState({
      defaultSvgParams: state => state.defaultSvgParams,
      sectors: state => state.sectors
    }),
    ...mapGetters({
      getIsClearMinorResultNeed: 'isClearMinorResultNeed'
    }),
    isClearMinorResultNeed() {
      return this.getIsClearMinorResultNeed(this.sectorId)
    },
    figures: () => figures,
    currentFigureComponent() {
      return formsComponents[this.buildingData.form]
    },
    sectorId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorId)
    },
    buildingData() {
      return this.sectorFromState.building
    }
  }
}
</script>
<style scoped lang="sass">
.sector-options
  background: $white
  width: 100%
  +media((padding: (320: rem(32) 0, 992: rem(40))))
  border-radius: rem(4)

  &__building
    +media((margin-top: (320: rem(32), 768: rem(51))))
    .inputs
      +media((margin: (0: 0 rem(24), 768: 0)))
      display: flex
      align-items: center
      flex-wrap: wrap
      justify-content: center
      +media((display: (0: grid, 960: flex)))
      $g-xs: repeat(2, 1fr)
      $g-l: repeat(3, rem(130))
      +media((grid-template-columns: (0: $g-xs, 480: $g-l)))
      +media((grid-gap: (0: rem(15), 576: rem(20), 960: unset)))
      &__title
        width: 100%
        margin: rem(17) 0 0
        @extend %18
        font-weight: bold
        color: $black
        text-align: center
        +media((grid-column: (0: span 2, 480: span 3)))
      .input-box
        +media((width: (960: calc(100% / 6))))
        +media((max-width: (768: rem(130))))
        +media((margin-top: (960: rem(20))))
        &:not(:last-child)
          +media((margin-right: (960: rem(20))))
    .figure
      svg
        max-width: 100%
        width: 100%
        height: auto
</style>
<style lang="sass">
.wizard
  &__buttons
    &-nav
      &--result
        width: 100%
        display: flex
        flex-direction: column
        margin-top: rem(-34)
        padding-bottom: rem(51)
        +media((align-items: (320: flex-start, 430: flex-end)))
        .text
          font-style: normal
          font-weight: 500
          font-size: rem(12)
          line-height: rem(20)
          color: $light_black
          text-align: left
          padding-top: rem(17)
          max-width: rem(320)
          +media((width: (320: calc(100% - 48px), 430: rem(320), 576: rem(300), 768: rem(320))))
          +media((margin-left: (320: rem(24), 430: 0)))
          +media((margin-right: (430: rem(24), 768: 0)))
</style>
