<template>
  <div class="form-selection">
    <div class="form-selection__title">Форма участка</div>
    <div class="form-selection__items">
      <selection-item
        v-for="item in figures"
        :item="item"
        :selected-form="selectedForm"
        :key="item.form"
        @updateForm="updateForm"
      />
    </div>
  </div>
</template>

<script>
import { shapes, figures } from '@/utils/figures'
import SelectionItem from '@/components/dump/Sector/SelectionItem'

export default {
  components: { SelectionItem },
  props: {
    selectedForm: {
      type: String,
      required: true,
      default: shapes.rectangle
    }
  },
  methods: {
    updateForm(form) {
      this.$emit('updateForm', form)
    }
  },
  name: 'BuildingFormSelection',
  computed: {
    figures: () => figures
  }
}
</script>

<style scoped lang="sass">
.form-selection
  box-shadow: inset 0 rem(-1) 0 $tn_gray
  +media((margin: (0: 0 auto, 992: 0)))
  +media((width: (0: calc(100% - 48px), 992: 100%)))
  &__title
    @extend %18
    color: $black
    font-weight: bold
    text-align: left
    margin-bottom: rem(24)
  &__items
    display: flex
    align-items: center
    overflow-x: auto
    overflow-y: hidden
    .item
      &:not(:last-child)
        margin-right: rem(4)
</style>
