<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      class="circle"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0
                10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#D6D6D6"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8 4.2666C10.4 4.2666 10.0666
                4.39993 9.7333 4.6666C9.46663 4.93327 9.3333 5.19993 9.3333 5.59993C9.3333 5.99993
                9.46663 6.2666 9.79997 6.53327C10.0666 6.79994 10.4666 6.93327 10.8666
                6.93327C11.2666 6.93327 11.6 6.79994 11.9333 6.53327C12.2 6.2666 12.3333 5.93327
                12.3333 5.59993C12.3333 5.19993 12.2 4.93327 11.9333 4.6666C11.5333 4.39993
                11.2 4.2666 10.8 4.2666ZM11.2 14.7333C10.8666 14.7333 10.6666 14.6666 10.5333
                14.5999C10.4 14.4666 10.3333 14.3333 10.3333 13.9999C10.3333 13.8666 10.3333 13.7333
                10.4 13.5333C10.4666 13.3333 10.4666 13.1333 10.5333 12.9333L11.1333
                10.7999C11.2 10.5999 11.2666 10.3999 11.2666 10.1333C11.2666 9.8666 11.3333 9.73327
                11.3333 9.6666C11.3333 9.19994 11.2 8.8666 10.8666 8.59993C10.5333 8.33327 10.0666
                8.19993 9.46663 8.19993C9.1333 8.19993 8.79997 8.2666 8.39997 8.39994C7.99997
                8.53327 7.66663 8.6666 7.26663 8.79993L7.1333 9.4666C7.26663 9.39993 7.39997
                9.39993 7.5333 9.33327C7.7333 9.2666 7.86663 9.2666 7.99997 9.2666C8.3333 9.2666
                8.5333 9.33327 8.66663 9.39994C8.79997 9.53327 8.86663 9.6666 8.86663
                9.99994C8.86663 10.1333 8.86663 10.3333 8.79997 10.4666C8.7333 10.6666 8.7333
                10.8666 8.66663 11.0666L7.99997 13.1333C7.9333 13.3333 7.9333 13.5333 7.86663
                13.7333C7.86663 13.9333 7.79997 14.0666 7.79997 14.2666C7.79997 14.7333 7.9333
                15.0666 8.26663 15.3333C8.59997 15.5999 9.06663 15.7333 9.66663 15.7333C10.0666
                15.7333 10.4 15.6666 10.6666 15.5999C10.9333 15.5333 11.3333 15.3333 11.8666
                15.1333L12 14.4666C11.9333 14.5333 11.8 14.5333 11.6 14.5999C11.5333 14.6666
                11.3333 14.7333 11.2 14.7333Z"
      fill="white"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'MaterialsInfoIcon'
}
</script>

<style scoped></style>
