<template>
  <div :class="wrapperClasses">
    <div class="zones__item-text" v-html="title"></div>
    <div class="zones__item-params--val">
      {{ item.value }}
      <span class="units">{{ units }}</span>
    </div>
  </div>
</template>

<script>
import { optionsText } from '@/utils/MinorResultTitles'

// const valuesToNormalize = {
//   granite: 'Гранитный щебень',
//   reinforced: 'Армированная стяжка',
//   custom: 'Мой вариант'
// }

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  name: 'CommonItem',
  computed: {
    wrapperClasses() {
      return ['zones__item-params', `${this.item.key}`]
    },
    title() {
      return optionsText[this.item.key].title()
    },
    units() {
      return optionsText[this.item.key].units
    }
    // normalizedValue() {
    //   const { value } = this.item
    //   return Object.keys(valuesToNormalize).includes(value) ? valuesToNormalize[value] : value
    // }
  }
}
</script>

<style scoped></style>
